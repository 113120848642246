import api from './api.js';

export default {
    // 拉取游戏列表
    // API：Game.do ? method = get_list
    // 参数：
    // start：起始条数(默认0)
    // size：条数(1- 20,默认20)
    // type：类型(默认0，即全部)
    // name： 游戏名称(默认空)
    // hot：热门(1为热门, 默认 - 1)
    // recommond：推荐(1为推荐, 默认 - 1)
    // 返回：
    // [{
    //     id: 游戏ID,
    //     name: 名称,
    //     icon: 图标,
    //     playUrl: 游戏入口,
    //     recommond: 推荐,
    //     hot: 热门,
    //     playNum: 游戏次数,
    //     state: 状态(-1为已经删除, 0为正常, 1为维护),
    //     types: [{ id: 类型id, name: 类型名称 }]
    // }]
    getGameList(start, size, type, name, hot, recommond) {
        return api.request(
            {
                url: '/Game.do?method=get_list',
                method: 'POST',
                data: {
                    start: start,
                    size: size,
                    type: type,
                    name: name,
                    hot: hot,
                    recommond: recommond,
                }
            },
        )
    },

    // 拉取游戏类型列表
    // API：GameType.do ? method = get_list
    // 参数：
    // 返回：
    // { id: 类型id, name: 名称, total: 游戏数量 }
    getCategories() {
        return api.request(
            {
                url: '/GameType.do?method=get_list',
                method: 'POST',
            },
        )
    },

    // 游戏入口 游戏属性playUrl接口
    // API： /Game.do?method=play
    // 返回
    // code: 2001表示游戏正在维护
    // code: 0返回以下数据
    // {
    //     id: 游戏id,
    //     url: 游戏入口地址url,
    //     res: 游戏zip地址,
    //     resmd5:游戏zip md5,
    //     reslibs: [
    //         {
    //            id: 资源库id,
    //            name: 资源库名称,
    //            res: 资源库zip地址
    //            resmd5: 资源库md5
    //     ]
    // }
    // res / reslibs存在，则先加载res / reslibs解压作为本地缓存, res不存在, 直接打开url
    getGame(gameId) {
        return api.request(
            {
                url: '/Game.do?method=play',
                method: 'POST',
                data: {
                    gameId: gameId,
                }
            },
        )
    },

    // 拉取游戏信息
    // API：Game.do ? method = get_infos
    // 参数：
    // ids 游戏id数组最大长度为20个
    // 返回：
    // [{
    //     id: 游戏ID,
    //     name: 名称,
    //     icon: 图标,
    //     playUrl: 游戏入口,
    //     recommond: 推荐,
    //     hot: 热门,
    //     playNum: 游戏次数,
    //     state: 状态(-1为已经删除, 0为正常, 1为维护),
    //     types: [{ id: 类型id, name: 类型名称 }]
    // }]
    getGamesInfo(ids) {
        return api.request(
            {
                url: '/Game.do?method=get_infos',
                method: 'POST',
                data: {
                    ids: ids,
                }
            },
        )
    },


    // 拉取游戏详情
    // API：Game.do ? method = get_info
    // 参数：
    // id 游戏id
    getGameInfo(id) {
        return api.request(
            {
                url: '/Game.do?method=get_info',
                method: 'POST',
                data: {
                    id: id,
                }
            },
        )
    }
}