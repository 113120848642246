<template>
  <div>
    <GameList :gameType="categoryid"></GameList>
  </div>
</template>

<script>
import GameList from '../components/GameList.vue';

export default {
  name: "category-view",
  components: {
    GameList,
  },
  created() { },
  data() {
    return {
      categoryname: this.$route.params.categoryname,
      categoryid: parseInt(this.$route.params.categoryid),
    };
  },
  watch: {
    $route(to) {
      this.categoryname = to.params.categoryname;
      this.categoryid = parseInt(this.$route.params.categoryid);
      // this.$forceUpdate();
    },
  },
};
</script>

<style lang="scss" scoped></style>