import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import CategoryView from '../views/CategoryView.vue';
import GameDetailView from '../views/GameDetailView.vue';
import PlayView from '../views/GamePlayView.vue';
import SearchView from '../views/SearchView.vue';
import TermsOfUseView from '../views/TermsOfUseView.vue';
import PrivacyPolicyView from '../views/PrivacyPolicyView.vue';
import NotFoundView from '../views/NotFoundView.vue';

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },
  {
    path: '/category/:categoryname&:categoryid',
    name: 'category',
    component: CategoryView
  },
  {
    path: '/game/:gamename&:gameid',
    name: 'game',
    component: GameDetailView
  },
  {
    path: '/play/:gamename&:gameid',
    name: 'play',
    component: PlayView
  },
  {
    path: '/search/:searchquery',
    name: 'search',
    component: SearchView
  },
  {
    path: '/terms',
    name: 'terms',
    component: TermsOfUseView
  },
  {
    path: '/privacy',
    name: 'privacy',
    component: PrivacyPolicyView
  },
  // 404 page
  {
    path: '*',
    name: 'not-found',
    component: NotFoundView
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
