<template>
  <div>
    <h1>404 - Page Not Found</h1>
    <p>The page you were looking for could not be found.</p>
    <router-link to="/">Back to home</router-link>
  </div>
</template>

<script>
export default {
  name: "NotFoundView",
};
</script>