<template>
  <b-container fluid style="max-width: 900px;">
    <h3 class="mt-3">Privacy Policy</h3>
    <div class="container" style="padding-top: 20px;">
      <p
        style="margin-top:0pt; margin-bottom:14pt; text-align:left; line-height:normal; widows:2; orphans:2; font-size:18pt;">
        <strong>PRIVACY POLICY</strong>
      </p>

      <p
        style="margin-top:0pt; margin-bottom:14pt; text-align:left; line-height:normal; widows:2; orphans:2; font-size:13.5pt; ">
        <span style="font-family:'Segoe UI'">The following privacy policy (“Privacy Policy”) sets forth
          fluentinsaying.com policy with respect to information, including personally identifiable
          data
          (“Personal
          Data”) and other information, Company collects from visitors to the Company website located
          at</span><span style="font-family:'Segoe UI'">&#xa0;</span><span
          style="font-family:'Segoe UI'">http://investigaze.com/
          (“Website”).</span><span style="font-family:'Segoe UI'">&#xa0;</span><span style="font-family:'Segoe UI'">
          Company, owner and operator of the Website, is a corporation formed under the laws of Hong Kong
          SAR.</span>
      </p>
      <p
        style="margin-top:14pt; margin-bottom:14pt; text-align:left; line-height:normal; widows:2; orphans:2; font-size:13.5pt; ">
        <span style="font-family:'Segoe UI'">We respect your privacy and are committed to protecting it. The purpose
          of
          this
          Privacy Policy is to inform you what Personal Data we may collect and how it may be used. This statement
          only
          applies to this Website.</span><span style="font-family:'Segoe UI'">&#xa0;</span><span
          style="font-family:'Segoe UI'"> This Privacy Policy does not apply to information of any kind that we
          collect by
          means (including offline means) or from sources other than those specified herein. If you choose to use
          the
          Website, your use is hereby deemed acceptance of Company’s practices described in this Privacy
          Policy.</span>
      </p>
      <p
        style="margin-top:14pt; margin-bottom:14pt; text-align:left; line-height:normal; widows:2; orphans:2; font-size:18pt; ">
        <strong><span style="font-family:'Segoe UI'; ">WHAT INFORMATION DO WE COLLECT AND HOW IS IT
            USED?</span></strong>
      </p>
      <ul type="disc" style="margin:0pt; padding-left:0pt">
        <li
          style="margin-left:20.1pt; text-align:left; line-height:normal; widows:2; orphans:2; padding-left:8.4pt; font-family:serif; font-size:10pt; ">
          <strong><span style="font-size:13.5pt; ">Automatically-Collected
              Information:</span></strong><strong><span style="font-size:13.5pt; ">&#xa0;</span></strong><span
            style="font-size:13.5pt">The Website
            automatically collects certain information about you and the device with which you access the
            Website.
            For
            example, when you use the Website, The Website will log your IP address, operating system type,
            browser
            type, referring website, pages you viewed, and the dates/times when you accessed the Website. The
            Website
            may also collect information about actions you take when using the Website, such as links
            clicked.</span>
        </li>
        <li
          style="margin-left:20.1pt; text-align:left; line-height:normal; widows:2; orphans:2; padding-left:8.4pt; font-family:serif; font-size:10pt; ">
          <strong><span style="font-size:13.5pt; ">Cookies:</span></strong><strong><span
              style="font-size:13.5pt; ">&#xa0;</span></strong><span style="font-size:13.5pt">The Website may
            log
            information using cookies, which are small data files stored on your browser by the Website. The
            Website
            may
            use both session cookies, which expire when you close your browser, and persistent cookies, which
            stay
            on
            your browser until deleted, to provide you with a more personalized experience on the
            Website.</span>
        </li>
      </ul>
      <p
        style="margin-top:14pt; margin-bottom:14pt; text-align:left; line-height:normal; widows:2; orphans:2; font-size:18pt; ">
        <strong><span style="font-family:'Segoe UI'; ">HOW YOUR INFORMATION MAY BE USED</span></strong>
      </p>
      <p
        style="margin-top:14pt; margin-bottom:14pt; text-align:left; line-height:normal; widows:2; orphans:2; font-size:13.5pt; ">
        <span style="font-family:'Segoe UI'">We may use the information collected in the following ways:</span>
      </p>
      <ul type="disc" style="margin:0pt; padding-left:0pt">
        <li
          style="margin-left:20.1pt; margin-bottom:5.25pt; text-align:left; line-height:normal; widows:2; orphans:2; padding-left:8.4pt; font-family:serif; font-size:10pt; ">
          <span style="font-size:13.5pt">To operate and maintain the Website;</span><span
            style="font-size:13.5pt">&#xa0;</span>
        </li>
        <li
          style="margin-left:20.1pt; margin-bottom:5.25pt; text-align:left; line-height:normal; widows:2; orphans:2; padding-left:8.4pt; font-family:serif; font-size:10pt; ">
          <span style="font-size:13.5pt">To track and measure advertising on the Website; or,</span>
        </li>
        <li
          style="margin-left:20.1pt; margin-bottom:5.25pt; text-align:left; line-height:normal; widows:2; orphans:2; padding-left:8.4pt; font-family:serif; font-size:10pt; ">
          <span style="font-size:13.5pt">To protect, investigate, and deter against unauthorized or illegal
            activity.</span>
        </li>
      </ul>
      <p
        style="margin-top:14pt; margin-bottom:14pt; text-align:left; line-height:normal; widows:2; orphans:2; font-size:18pt; ">
        <strong><span style="font-family:'Segoe UI'; ">LEGAL BASES FOR PROCESSING DATA UNDER THE GENERAL DATA
            PROTECTION
            REGULATION (“GDPR”)</span></strong>
      </p>
      <p
        style="margin-top:14pt; margin-bottom:14pt; text-align:left; line-height:normal; widows:2; orphans:2; font-size:13.5pt; ">
        <span style="font-family:'Segoe UI'">We are responsible as a “controller” of your Personal Data under the
          GDPR
          and
          below are the types of lawful basis that we will rely on to collect and process your Personal
          Data:</span>
      </p>
      <ul type="disc" style="margin:0pt; padding-left:0pt">
        <li
          style="margin-left:20.1pt; text-align:left; line-height:normal; widows:2; orphans:2; padding-left:8.4pt; font-family:serif; font-size:10pt; ">
          <strong><span style="font-size:13.5pt; ">Legitimate Interest</span></strong><span
            style="font-size:13.5pt">&#xa0;</span><span style="font-size:13.5pt">means the interest of our
            business
            in
            conducting and managing our business to enable us to give you the best service/product and the best
            and
            most
            secure experience. We make sure we consider and balance any potential impact on you (both positive
            and
            negative) and your rights before we process your Personal Data for our legitimate interests. We do
            not
            use
            your Personal Data for activities where our interests are overridden by the impact on you (unless we
            have
            your consent or are otherwise required or permitted to by law). You can obtain further information
            about
            how
            we assess our legitimate interests against any potential impact on you in respect of specific
            activities
            by
            contacting us at</span><span style="font-size:13.5pt">&#xa0;</span><span
            style="font-size:13.5pt">laminehached@gmail.com.</span>
        </li>
        <li
          style="margin-left:20.1pt; text-align:left; line-height:normal; widows:2; orphans:2; padding-left:8.4pt; font-family:serif; font-size:10pt; ">
          <strong><span style="font-size:13.5pt; ">Performance of Contract</span></strong><span
            style="font-size:13.5pt">&#xa0;</span><span style="font-size:13.5pt">means processing your data
            where it
            is
            necessary for the performance of a contract to which you are a party or to take steps at your
            request
            before
            entering into such a contract.</span>
        </li>
        <li
          style="margin-left:20.1pt; text-align:left; line-height:normal; widows:2; orphans:2; padding-left:8.4pt; font-family:serif; font-size:10pt; ">
          <strong><span style="font-size:13.5pt; ">Comply with a legal or regulatory
              obligation</span></strong><span style="font-size:13.5pt">&#xa0;</span><span style="font-size:13.5pt">means
            processing your Personal Data
            where it is necessary for compliance with a legal or regulatory obligation to which we are
            subject.</span>
        </li>
        <li
          style="margin-left:20.1pt; text-align:left; line-height:normal; widows:2; orphans:2; padding-left:8.4pt; font-family:serif; font-size:10pt; ">
          <strong><span style="font-size:13.5pt; ">Consent</span></strong><span
            style="font-size:13.5pt">&#xa0;</span><span style="font-size:13.5pt">means where you have consented
            to a
            certain use of your Personal Data.</span>
        </li>
        <li
          style="margin-left:20.1pt; text-align:left; line-height:normal; widows:2; orphans:2; padding-left:8.4pt; font-family:serif; font-size:10pt; ">
          <strong><span style="font-size:13.5pt; ">Vital Interest</span></strong><span
            style="font-size:13.5pt">&#xa0;</span><span style="font-size:13.5pt">means processing your Personal
            Data
            to
            protect the vital interests of the data subject or another natural person.</span>
        </li>
      </ul>
      <h1
        style="margin-top:14pt; margin-bottom:14pt; text-align:left; line-height:normal; widows:2; orphans:2; font-size:18pt; ">
        <span style="font-family:'Segoe UI'">WHERE YOUR PERSONAL DATA IS HELD</span>
      </h1>
      <p
        style="margin-top:14pt; margin-bottom:14pt; text-align:left; line-height:normal; widows:2; orphans:2; font-size:13.5pt; ">
        <span style="font-family:'Segoe UI'">Personal Data may be held at our offices and those of our affiliates,
          parent or
          related companies, third party Personal Data may be held at our offices and those of our affiliates,
          parent,
          or
          related companies, third party agencies, service providers, representatives and agents as described
          below
          (see
          below: “Third Party Use of Personal Information”).</span>
      </p>
      <h1
        style="margin-top:14pt; margin-bottom:14pt; text-align:left; line-height:normal; widows:2; orphans:2; font-size:18pt; ">
        <span style="font-family:'Segoe UI'">THIRD-PARTY USE OF PERSONAL INFORMATION</span>
      </h1>
      <p
        style="margin-top:14pt; margin-bottom:14pt; text-align:left; line-height:normal; widows:2; orphans:2; font-size:13.5pt; ">
        <span style="font-family:'Segoe UI'">Company may share your Personal Data with certain third parties as set
          forth
          below:</span>
      </p>
      <p
        style="margin-top:14pt; margin-bottom:14pt; text-align:left; line-height:normal; widows:2; orphans:2; font-size:13.5pt; ">
        <strong>Legal Requirements</strong><span style="font-family:'Segoe UI'">: Company may disclose your Personal
          Data if
          required to do so by law or in the good faith belief that such action is necessary to (i) comply with a
          legal
          obligation or to respond to requests from law enforcement or other government officials relating to
          investigations or alleged illegal activity or in connection with our own investigation of suspected or
          actual
          illegal activity, in which case we may (and you hereby authorize us to) disclose information without
          subpoenas
          or warrants served on us, (ii) protect and defend the rights or property of Company, (iii) act in urgent
          circumstances to protect the personal safety of users of the Website or the public, (iv) protect against
          legal
          liability including to resolve disputes, investigate problems, or enforce our customer contracts.</span>
      </p>
      <p
        style="margin-top:14pt; margin-bottom:14pt; text-align:left; line-height:normal; widows:2; orphans:2; font-size:13.5pt; ">
        <span style="font-family:'Segoe UI'">The Website may share your information with third parties when you
          explicitly
          authorize us to share your information. Additionally, the Website may use third-party service providers
          to
          service various aspects of the Website. Each third-party service provider’s use of your personal
          information
          is
          dictated by their respective privacy policies. The Website currently uses the following third-party
          service
          providers:</span><span style="font-family:'Segoe UI'">&#xa0;</span>
      </p>
      <ul type="disc" style="margin:0pt; padding-left:0pt">
        <li
          style="margin-left:20.1pt; text-align:left; line-height:normal; widows:2; orphans:2; padding-left:8.4pt; font-family:serif; font-size:10pt; ">
          <strong><span style="font-size:13.5pt; ">Google Analytics</span></strong><span
            style="font-size:13.5pt">&#xa0;</span><span style="font-size:13.5pt">– this service tracks Website
            usage
            and
            provides information such as referring websites and user actions on the Website. Google Analytics
            may
            capture your IP address, but no other personal information is captured by Google
            Analytics.</span><span style="font-size:13.5pt">&#xa0;</span>
        </li>
        <li
          style="margin-left:20.1pt; text-align:left; line-height:normal; widows:2; orphans:2; padding-left:8.4pt; font-family:serif; font-size:10pt; ">
          <strong><span style="font-size:13.5pt; ">Google AdSense</span></strong><span
            style="font-size:13.5pt">&#xa0;</span><span style="font-size:13.5pt">– this service is used to
            manage
            our
            display advertisements. Please see the display advertising section for more information about data
            collection.</span>
        </li>
      </ul>
      <p
        style="margin-top:14pt; margin-bottom:14pt; text-align:left; line-height:normal; widows:2; orphans:2; font-size:13.5pt; ">
        <span style="font-family:'Segoe UI'">At this time, your personal information is not shared with any other
          third-party service providers. This list may be amended from time to time in the Website’s sole
          discretion.</span><span style="font-family:'Segoe UI'">&#xa0;</span>
      </p>
      <p
        style="margin-top:14pt; margin-bottom:14pt; text-align:left; line-height:normal; widows:2; orphans:2; font-size:13.5pt; ">
        <span style="font-family:'Segoe UI'">Except when required by law, Company will not sell, distribute, or
          reveal
          your
          email addresses or other personal information without your consent; however, Company may disclose or
          transfer
          personal information collected through the Website to third parties who acquire all or a portion of our
          business, which may be the result of a merger, consolidation, or purchase of all or a portion of our
          assets,
          or
          in connection with any bankruptcy or reorganization proceeding brought by or against us.</span>
      </p>
      <h1
        style="margin-top:14pt; margin-bottom:14pt; text-align:left; line-height:normal; widows:2; orphans:2; font-size:18pt; ">
        <span style="font-family:'Segoe UI'">ANONYMOUS DATA</span>
      </h1>
      <p
        style="margin-top:14pt; margin-bottom:14pt; text-align:left; line-height:normal; widows:2; orphans:2; font-size:13.5pt; ">
        <span style="font-family:'Segoe UI'">From time to time, Company may use anonymous data, which does not
          identify
          you
          alone, or when combined with data from other parties. This type of anonymous data may be provided to
          other
          parties for advertising. Examples of this anonymous data may include analytics or information collected
          from
          cookies.</span><span style="font-family:'Segoe UI'">&#xa0;</span>
      </p>
      <h1
        style="margin-top:14pt; margin-bottom:14pt; text-align:left; line-height:normal; widows:2; orphans:2; font-size:18pt; ">
        <span style="font-family:'Segoe UI'">COOKIES</span>
      </h1>
      <p
        style="margin-top:14pt; margin-bottom:14pt; text-align:left; line-height:normal; widows:2; orphans:2; font-size:13.5pt; ">
        <span style="font-family:'Segoe UI'">The Website uses cookies to store visitors’ preferences,</span><span
          style="font-family:'Segoe UI'">&#xa0;</span><span style="font-family:'Segoe UI'">record user-specific
          information on what pages users access or visit,</span><span style="font-family:'Segoe UI'">&#xa0;</span><span
          style="font-family:'Segoe UI'">ensure that visitors
          are
          not repeatedly sent the same banner ads,</span><span style="font-family:'Segoe UI'">&#xa0;</span><span
          style="font-family:'Segoe UI'">customize content based on
          visitors’ browser type or other</span><span style="font-family:'Segoe UI'">&#xa0;</span><span
          style="font-family:'Segoe UI'">information that the visitor sends. Cookies may also be used by
          third-party
          services, such as Google Analytics, as described herein.</span><span
          style="font-family:'Segoe UI'">&#xa0;</span>
      </p>
      <h2
        style="margin-top:2pt; margin-bottom:0pt; page-break-inside:avoid; page-break-after:avoid; line-height:108%; font-size:13pt; ">
        <span style="font-family:'Segoe UI'; font-weight:normal">What are cookies?</span>
      </h2>
      <p
        style="margin-top:14pt; margin-bottom:14pt; text-align:left; line-height:normal; widows:2; orphans:2; font-size:13.5pt; ">
        <span style="font-family:'Segoe UI'">A cookie is a small text file which is sent to your computer or mobile
          device
          (referred to in this policy as a “device”) by the web server so that a website can remember some
          information
          about your browsing activity on the Website. </span><span style="font-family:'Segoe UI'">&#xa0;</span><span
          style="font-family:'Segoe UI'">The cookie will collect
          information relating to your use of the Website,
          information about your device such as the device’s IP address and browser type, demographic data and, if
          you
          arrived at the Website via a link from third party site, the URL of the linking page.</span>
      </p>
      <p
        style="margin-top:14pt; margin-bottom:14pt; text-align:left; line-height:normal; widows:2; orphans:2; font-size:13.5pt; ">
        <span style="font-family:'Segoe UI'">In addition to cookies, the Website may use web beacons. </span><span
          style="font-family:'Segoe UI'">&#xa0;</span><span style="font-family:'Segoe UI'">Web beacons allow us to
          count
          the number of users who have visited or accessed the Website and to recognize users by accessing our
          cookies. We
          may employ web beacons to facilitate Website administration and navigation, to track the actions of
          users of
          the
          Website, to compile aggregate statistics about Website usage and response rates, and to provide an
          enhanced
          online experience for visitors to the Website. We may also include web beacons in HTML-formatted e-mail
          messages
          that we send to determine which e-mail messages were opened. A web beacon is often invisible because it
          is
          only
          1 x 1 pixel in size with no color. </span><span style="font-family:'Segoe UI'">&#xa0;</span><span
          style="font-family:'Segoe UI'">A web beacon can also be known as a web bug, 1 by 1 GIF, invisible GIF,
          and
          tracker GIF.</span><span style="font-family:'Segoe UI'">&#xa0;</span>
      </p>
      <h2
        style="margin-top:2pt; margin-bottom:0pt; page-break-inside:avoid; page-break-after:avoid; line-height:108%; font-size:13pt; ">
        <span style="font-family:'Segoe UI'; font-weight:normal">What are the different types of cookies and how do
          we
          use
          them?</span>
      </h2>
      <ul type="disc" style="margin:0pt; padding-left:0pt">
        <li
          style="margin-left:20.1pt; text-align:left; line-height:normal; widows:2; orphans:2; padding-left:8.4pt; font-family:serif; font-size:10pt; ">
          <strong><span style="font-size:13.5pt; ">Essential –</span></strong><span
            style="font-size:13.5pt">&#xa0;</span><span style="font-size:13.5pt">These are cookies which are
            essential
            for the running of the Website. Without these cookies, parts of the Website would not function.
          </span><span style="font-size:13.5pt">&#xa0;</span><span style="font-size:13.5pt">These cookies do not
            track
            where you
            have been on the internet and do not gather information about you that could be used for marketing
            purposes.</span><br><br><span style="font-size:13.5pt">Examples of how we may use essential cookies
            include:</span>
          <ul type="disc" style="margin-right:0pt; margin-left:0pt; padding-left:0pt">
            <li style="margin-top:5.25pt; margin-left:20.1pt; margin-bottom:5.25pt; padding-left:8.4pt; ">
              <span style="font-size:13.5pt">Setting unique identifiers for each unique visitor, so that
                Website
                numbers can be analyzed.</span>
            </li>
          </ul>
        </li>
        <li
          style="margin-left:20.1pt; text-align:left; line-height:normal; widows:2; orphans:2; padding-left:8.4pt; font-family:serif; font-size:10pt; ">
          <strong><span style="font-size:13.5pt; ">Functional –</span></strong><span
            style="font-size:13.5pt">&#xa0;</span><span style="font-size:13.5pt">These cookies are used to
            remember
            your
            preferences on the Website and to provide enhanced, more personal features. The information
            collected by
            these cookies is usually anonymized, so we cannot identify you personally. </span><span
            style="font-size:13.5pt">&#xa0;</span><span style="font-size:13.5pt">Functional cookies do not track
            your
            internet usage or gather information which could be used for selling
            advertising.</span><br><br><span style="font-size:13.5pt">Examples of how we may use functional
            cookies include:</span>
          <ul type="disc" style="margin-right:0pt; margin-left:0pt; padding-left:0pt">
            <li style="margin-top:5.25pt; margin-left:20.1pt; margin-bottom:5.25pt; padding-left:8.4pt; ">
              <span style="font-size:13.5pt">Gathering data about visits to the Website, including numbers of
                visitors
                and visits, length of time spent on the Website, pages clicked on or where visitors have
                come
                from;</span>
            </li>
          </ul>
          <ul type="disc" style="margin-right:0pt; margin-left:0pt; padding-left:0pt">
            <li style="margin-left:20.1pt; margin-bottom:5.25pt; padding-left:8.4pt; ">
              <span style="font-size:13.5pt">Eliminating the need for returning users to re-enter their login
                details.</span>
            </li>
          </ul>
        </li>
        <li
          style="margin-left:20.1pt; text-align:left; line-height:normal; widows:2; orphans:2; padding-left:8.4pt; font-family:serif; font-size:10pt; ">
          <strong><span style="font-size:13.5pt; ">Analytical Performance –</span></strong><span
            style="font-size:13.5pt">&#xa0;</span><span style="font-size:13.5pt">Analytical performance cookies
            are
            used
            to monitor the performance of the Website, for example, to determine the number of page views and
            the
            number
            of unique users our Website has. We use this information to improve user experience or identify
            areas of
            the
            Website which may require maintenance. </span><span style="font-size:13.5pt">&#xa0;</span><span
            style="font-size:13.5pt">The information is anonymous (i.e., it cannot be used to identify you and
            does
            not
            contain personal information such as your name and email address) and it is only used for
            statistical
            purposes.</span>
          <ul type="disc" style="margin-right:0pt; margin-left:0pt; padding-left:0pt">
            <li style="margin-top:5.25pt; margin-left:20.1pt; margin-bottom:5.25pt; padding-left:8.4pt; ">
              <span style="font-size:13.5pt">Examples of how we may use analytical cookies include:</span>
              <ul type="disc" style="margin-right:0pt; margin-left:0pt; padding-left:0pt">
                <li style="margin-top:5.25pt; margin-left:20.1pt; margin-bottom:5.25pt; padding-left:8.4pt; ">
                  <span style="font-size:13.5pt">Measuring users’ behavior;</span>
                </li>
              </ul>
              <ul type="disc" style="margin-right:0pt; margin-left:0pt; padding-left:0pt">
                <li style="margin-left:20.1pt; margin-bottom:5.25pt; padding-left:8.4pt; ">
                  <span style="font-size:13.5pt">Analyzing which pages are viewed and how long for and
                    which
                    links
                    are followed to better develop the Website.</span>
                </li>
              </ul>
            </li>
          </ul>
        </li>
        <li
          style="margin-left:20.1pt; text-align:left; line-height:normal; widows:2; orphans:2; padding-left:8.4pt; font-family:serif; font-size:10pt; ">
          <strong><span style="font-size:13.5pt; ">Advertising –</span></strong><strong><span
              style="font-size:13.5pt; ">&#xa0;</span></strong><span style="font-size:13.5pt">Behavioral
            advertising
            cookies, which may be placed on your device by us or our trusted third-party service providers,
            remember
            that you have visited a website and use that information to provide you with advertising which is
            tailored
            to your interests. This is often called online behavioral advertising and is done by grouping
            together
            shared interests based upon web browsing history. Your web browsing history can be used to infer
            things
            about you (e.g. your age, gender etc.), and this information may also be used to make advertising on
            websites more relevant to you. </span><span style="font-size:13.5pt">&#xa0;</span><span
            style="font-size:13.5pt">Although behavioral advertising cookies can track your activity around the
            internet, these cookies cannot identify you personally.</span><br><br><span
            style="font-size:13.5pt">Examples of how we may use advertising cookies include:</span>
          <ul type="disc" style="margin-right:0pt; margin-left:0pt; padding-left:0pt">
            <li style="margin-top:5.25pt; margin-left:20.1pt; margin-bottom:5.25pt; padding-left:8.4pt; ">
              <span style="font-size:13.5pt">Providing advertising to you and enabling us to manage our
                relationship
                with those advertisers by, for example, tracking how many unique users have seen a
                particular
                advertisement or followed a link in an advertisement.</span>
            </li>
          </ul>
        </li>
        <li
          style="margin-left:20.1pt; text-align:left; line-height:normal; widows:2; orphans:2; padding-left:8.4pt; font-family:serif; font-size:10pt; ">
          <strong><span style="font-size:13.5pt; ">Third Party Cookies –</span></strong><strong><span
              style="font-size:13.5pt; ">&#xa0;</span></strong><span style="font-size:13.5pt">You may notice
            on
            some
            pages of the Website that Cookies have been set that are not related to us. When you visit a page
            with
            content embedded from, for example, YouTube or Facebook, these third party service providers may set
            their
            own cookies on your device. We do not control the use of these third party cookies and cannot access
            them
            due to the way that cookies work, as cookies can only be accessed by the party who originally set
            them.
            Please check the third party websites for more information about these cookies.</span>
        </li>
      </ul>
      <p
        style="margin-top:14pt; margin-bottom:14pt; text-align:left; line-height:normal; widows:2; orphans:2; font-size:13.5pt; ">
        <span style="font-family:'Segoe UI'">Users may, at any time, prevent the setting of cookies, by the Website,
          by
          using a corresponding setting of your internet browser and may thus permanently deny the setting of
          cookies.
          Furthermore, already set cookies may be deleted at any time via an Internet browser or other software
          programs.
          This is possible in all popular Internet browsers. However, if you deactivate the setting of cookies in
          your
          Internet browser, not all functions of our Website may be entirely usable.</span>
      </p>
      <h2
        style="margin-top:2pt; margin-bottom:0pt; page-break-inside:avoid; page-break-after:avoid; line-height:108%; font-size:13pt; ">
        <span style="font-family:'Segoe UI'; font-weight:normal">How can you manage or opt-out of cookies?</span>
      </h2>
      <p
        style="margin-top:14pt; margin-bottom:14pt; text-align:left; line-height:normal; widows:2; orphans:2; font-size:13.5pt; ">
        <span style="font-family:'Segoe UI'">Cookies, including those which have already been set, can be deleted
          from
          your
          hard drive. You can also change the preferences/settings in your web browser to control cookies. In some
          cases,
          you can choose to accept cookies from the primary site, but block them from third parties. In others,
          you
          can
          block cookies from specific advertisers, or clear out all cookies. Deleting or blocking cookies may
          reduce
          functionality of the Website. To learn more about how to reject cookies, visit</span>
        <span style="font-family:'Segoe UI'">&#xa0;</span>
        <a href="http://www.allaboutcookies.org/" style="text-decoration:none">
          <span style="color:#fff">www.allaboutcookies.org</span>
        </a>
        <span style="font-family:'Segoe UI'">&#xa0;</span>
        <span style="font-family:'Segoe UI'">or go to the help menu
          within
          your internet browser. If you experience any problems having deleted cookies, you should contact the
          supplier of
          your web browser.</span>
      </p>
      <h2
        style="margin-top:2pt; margin-bottom:0pt; page-break-inside:avoid; page-break-after:avoid; line-height:108%; font-size:13pt; ">
        <span style="font-family:'Segoe UI'; font-weight:normal">Opting out of Analytical Performance Cookies</span>
      </h2>
      <p
        style="margin-top:14pt; margin-bottom:14pt; text-align:left; line-height:normal; widows:2; orphans:2; font-size:13.5pt; ">
        <span style="font-family:'Segoe UI'">If you would like to opt out of Analytics cookies, please do so by
          clicking
          on
          the links below:</span>
      </p>
      <ul type="disc" style="margin:0pt; padding-left:0pt">
        <li
          style="margin-left:20.1pt; text-align:left; line-height:normal; widows:2; orphans:2; padding-left:8.4pt; font-family:serif; font-size:10pt; ">
          <strong><span style="font-size:13.5pt; ">Google Analytics:</span></strong><span
            style="font-size:13.5pt">&#xa0;</span><a href="https://tools.google.com/dlpage/gaoptout"
            style="text-decoration:none"><span
              style="font-size:13.5pt; color:#000000">https://tools.google.com/dlpage/gaoptout</span></a><span
            style="font-size:13.5pt">.</span>
        </li>
      </ul>
      <h2
        style="margin-top:2pt; margin-bottom:0pt; page-break-inside:avoid; page-break-after:avoid; line-height:108%; font-size:13pt; ">
        <span style="font-family:'Segoe UI'; font-weight:normal">Opting out of Behavioral Advertising Cookies</span>
      </h2>
      <h1
        style="margin-top:14pt; margin-bottom:14pt; text-align:left; line-height:normal; widows:2; orphans:2; font-size:18pt; ">
        <span style="font-family:'Segoe UI'">ADVERTISING</span>
      </h1>
      <h2
        style="margin-top:2pt; margin-bottom:0pt; page-break-inside:avoid; page-break-after:avoid; line-height:108%; font-size:13pt; ">
        <span style="font-family:'Segoe UI'; font-weight:normal">Display Ads</span>
      </h2>
      <p
        style="margin-top:14pt; margin-bottom:14pt; text-align:left; line-height:normal; widows:2; orphans:2; font-size:13.5pt; ">
        <span style="font-family:'Segoe UI'">The Website uses Google AdSense to manage all third-party advertising
          on
          the
          Website. Google AdSense serves content and advertisements when you visit the Website, which may use
          first
          and
          third-party cookies. A cookie is a small text file which is sent to your computer or mobile device
          (referred
          to
          in this policy as a “device”) by the web server so that a website can remember some information about
          your
          browsing activity on the Website. The cookie may collect information relating to your use of the
          Website,
          information about your device such as the device’s IP address and browser type, demographic data and, if
          you
          arrived at the Website via a link from a third-party site, the URL of the linking page.</span><span
          style="font-family:'Segoe UI'">&#xa0;</span><span style="font-family:'Segoe UI'">&#xa0;</span><span
          style="font-family:'Segoe UI'">&#xa0;</span>
      </p>
      <p
        style="margin-top:14pt; margin-bottom:14pt; text-align:left; line-height:normal; widows:2; orphans:2; font-size:13.5pt; ">
        <span style="font-family:'Segoe UI'">First party cookies are created by the website that you are visiting. A
          third-party cookie is frequently used in behavioral advertising and analytics and is created by a domain
          other
          than the website you are visiting.</span><span style="font-family:'Segoe UI'">&#xa0;</span><span
          style="font-family:'Segoe UI'"> Third-party cookies, tags, pixels, beacons and other similar
          technologies
          (collectively, “Tags”) may be placed on the Website to monitor interaction with advertising content and
          to
          target and optimize advertising. Each internet browser has functionality so that you can block both
          first
          and
          third-party cookies and clear your browser’s cache.</span><span
          style="font-family:'Segoe UI'">&#xa0;</span><span style="font-family:'Segoe UI'">The “help” feature of
          the
          menu
          bar on most browsers will tell you how to stop accepting new cookies, how to receive notification of new
          cookies, how to disable existing cookies and how to clear your browser’s cache. </span>
      </p>
      <p
        style="margin-top:14pt; margin-bottom:14pt; text-align:left; line-height:normal; widows:2; orphans:2; font-size:13.5pt; ">
        <span style="font-family:'Segoe UI'">Without cookies you may not be able to take full advantage of the
          Website
          content and features.</span><span style="font-family:'Segoe UI'">&#xa0;</span><span
          style="font-family:'Segoe UI'"> Please note that rejecting cookies does not mean that you will no longer
          see
          ads
          when you visit our Site.</span>
      </p>
      <p
        style="margin-top:14pt; margin-bottom:14pt; text-align:left; line-height:normal; widows:2; orphans:2; font-size:13.5pt; ">
        <span style="font-family:'Segoe UI'">The Website may collect IP addresses and location</span><span
          style="font-family:'Segoe UI'">&#xa0;</span><span style="font-family:'Segoe UI'">information to serve
          personalized ads and pass it to Google AdSense.</span>
      </p>
      <h2
        style="margin-top:2pt; margin-bottom:0pt; page-break-inside:avoid; page-break-after:avoid; line-height:108%; font-size:13pt; ">
        <span style="font-family:'Segoe UI'; font-weight:normal">Retargeting Ads</span>
      </h2>
      <p
        style="margin-top:14pt; margin-bottom:14pt; text-align:left; line-height:normal; widows:2; orphans:2; font-size:13.5pt; ">
        <span style="font-family:'Segoe UI'">From time to time, the Website may engage in remarketing efforts with
          third-party companies, such as Google, Facebook, or Instagram, in order to market the Website. These
          companies
          use cookies to serve ads based on someone’s past visits to the Website. This means, after visiting the
          Website,
          you may see an ad for our services. However, your personally identifiable information is not used by any
          remarketing service other than to present you offers from Company. We use the following third-party
          service
          providers for remarketing:</span>
      </p>
      <ul type="disc" style="margin:0pt; padding-left:0pt">
        <li
          style="margin-left:20.1pt; text-align:left; line-height:normal; widows:2; orphans:2; padding-left:8.4pt; font-family:serif; font-size:10pt; ">
          <strong><span style="font-size:13.5pt; ">Google:</span></strong><strong><span
              style="font-size:13.5pt; ">&#xa0;</span></strong><span style="font-size:13.5pt">Opt-out of
            Google
            remarketing</span><span style="font-size:13.5pt">&#xa0;</span><a
            href="https://support.google.com/ads/answer/2662922?hl=en" target="_blank"
            style="text-decoration:none"><span style="font-size:13.5pt; color:#000000">here</span></a>
        </li>
      </ul>
      <h2
        style="margin-top:2pt; margin-bottom:0pt; page-break-inside:avoid; page-break-after:avoid; line-height:108%; font-size:13pt; ">
        <span style="font-family:'Segoe UI'; font-weight:normal">Sponsored Content Tracking Pixels</span>
      </h2>
      <p
        style="margin-top:14pt; margin-bottom:14pt; text-align:left; line-height:normal; widows:2; orphans:2; font-size:13.5pt; ">
        <span style="font-family:'Segoe UI'">This Website may engage in sponsored campaigns with various influencer
          networks, brands, and agencies. All sponsored content is duly disclosed in accordance with the FTC’s
          requirements. From time to time, these sponsored campaigns utilize tracking pixels (aka web beacons),
          which
          may
          contain cookies to collect data regarding usage and audience. This information is collected by the
          sponsoring
          company to track the results of the campaign. As stated above, these pixels may capture your IP address
          on
          behalf of the content sponsor. No personally identifiable information collected by the Website is used
          in
          conjunction with these tracking pixels.</span>
      </p>
      <h1
        style="margin-top:14pt; margin-bottom:14pt; text-align:left; line-height:normal; widows:2; orphans:2; font-size:18pt; ">
        <span style="font-family:'Segoe UI'">HOW LONG WILL YOUR PERSONAL INFORMATION BE KEPT</span>
      </h1>
      <p
        style="margin-top:14pt; margin-bottom:14pt; text-align:left; line-height:normal; widows:2; orphans:2; font-size:13.5pt; ">
        <span style="font-family:'Segoe UI'">We will keep your personal information while you are visiting the
          website,
          or
          we are providing products or services to you. </span>
      </p>
      <p
        style="margin-top:14pt; margin-bottom:14pt; text-align:left; line-height:normal; widows:2; orphans:2; font-size:13.5pt; ">
        <span style="font-family:'Segoe UI'">We will not retain your personal information for longer than necessary
          for
          the
          purposes set out in this Privacy Policy. Different retention periods apply for different types of
          personal
          information. When it is no longer necessary to retain your personal information, we will delete or
          anonymize
          it.</span>
      </p>
      <h1
        style="margin-top:14pt; margin-bottom:14pt; text-align:left; line-height:normal; widows:2; orphans:2; font-size:18pt; ">
        <span style="font-family:'Segoe UI'">RIGHTS RELATED TO YOUR PERSONAL INFORMATION</span>
      </h1>
      <p
        style="margin-top:14pt; margin-bottom:14pt; text-align:left; line-height:normal; widows:2; orphans:2; font-size:13.5pt; ">
        <span style="font-family:'Segoe UI'">Under laws in certain countries in which we operate, customers and
          other
          visitors to our Website from those countries have a right to delete Personal Data about
          themselves.</span><span style="font-family:'Segoe UI'">&#xa0;</span><span style="font-family:'Segoe UI'"> We
          will, upon your request,
          take reasonable steps to delete your Personal Data</span><span style="font-family:宋体">.</span>
      </p>
      <p
        style="margin-top:14pt; margin-bottom:14pt; text-align:left; line-height:normal; widows:2; orphans:2; font-size:13.5pt; ">
        <span style="font-family:'Segoe UI'">Please note that while any deletions will be reflected in active user
          databases
          (as updated within a reasonable period of time), we may retain all Personal Data for backups, archiving,
          prevention of fraud and abuse, analytics, and satisfaction of other legal obligations we reasonably
          believe
          applicable.</span>
      </p>
      <p
        style="margin-top:14pt; margin-bottom:14pt; text-align:left; line-height:normal; widows:2; orphans:2; font-size:13.5pt; ">
        <strong>Forget</strong><span style="font-family:'Segoe UI'">&#xa0;</span><span style="font-family:'Segoe UI'">–
          In
          certain situations, you may request that we erase or forget your Personal Data. To do so, please submit
          a
          request to</span><span style="font-family:'Segoe UI'">&#xa0;</span>laminehached@gmail.com<span
          style="font-family:'Segoe UI'">.</span>
      </p>
      <p
        style="margin-top:14pt; margin-bottom:14pt; text-align:left; line-height:normal; widows:2; orphans:2; font-size:13.5pt; ">
        <span style="font-family:'Segoe UI'">Please note that we may need to retain certain information for
          recordkeeping
          purposes or to complete transactions, or when required by law.</span>
      </p>
      <h1
        style="margin-top:14pt; margin-bottom:14pt; text-align:left; line-height:normal; widows:2; orphans:2; font-size:18pt; ">
        <span style="font-family:'Segoe UI'">CALIFORNIA RESIDENTS</span>
      </h1>
      <p
        style="margin-top:14pt; margin-bottom:14pt; text-align:left; line-height:normal; widows:2; orphans:2; font-size:13.5pt; ">
        <strong>California Residents:</strong><strong>&#xa0;</strong><span
          style="font-family:'Segoe UI'">&#xa0;</span><span style="font-family:'Segoe UI'">Pursuant to the
          California
          Consumer Privacy Act of 2019 (“CCPA”), you have
          certain rights with respect to your personal information.</span><span
          style="font-family:'Segoe UI'">&#xa0;</span><span style="font-family:'Segoe UI'"> For the purposes of
          this
          section, personal information is used as defined in the CCPA.</span><span
          style="font-family:'Segoe UI'">&#xa0;</span><span style="font-family:'Segoe UI'"> Company reserves the
          right
          to
          verify all requests made pursuant to the CCPA.</span>
      </p>
      <h2
        style="margin-top:0pt; margin-bottom:0pt; page-break-inside:avoid; page-break-after:avoid; line-height:108%; font-size:13pt; ">
        <span style="font-weight:normal">Access and Disclosure</span>
      </h2>
      <p
        style="margin-top:14pt; margin-bottom:14pt; text-align:left; line-height:normal; widows:2; orphans:2; font-size:13.5pt; ">
        <span style="font-family:'Segoe UI'">You may make a verifiable request that we disclose the categories of
          personal
          information we have collected about you, the categories of sources of the personal information we
          collected
          about you, the business or commercial purpose for collecting or selling the personal information, the
          categories
          of third parties with whom we share your personal information, our use of the personal information and
          if
          the
          personal information was disclosed or sold to third parties, including the categories of personal
          information
          sold or disclosed.</span><span style="font-family:'Segoe UI'">&#xa0;</span><span
          style="font-family:'Segoe UI'">
          You also have the right to make a verifiable request for a copy of the personal information collected
          about
          you
          for the twelve (12) months prior to the date of your request. </span><span
          style="font-family:'Segoe UI'">&#xa0;</span><span style="font-family:'Segoe UI'"> To see any categories
          of
          personal information we have sold in the last twelve (12) months or opt out of the sale or disclosure of
          personal information, please refer to the Do Not Sell My Personal Information link on each page of this
          Website.
        </span><span style="font-family:'Segoe UI'">&#xa0;</span><span style="font-family:'Segoe UI'">Please note
          that
          we
          are not required to:</span>
      </p>
      <ul type="disc" style="margin:0pt; padding-left:0pt">
        <li
          style="margin-left:20.1pt; margin-bottom:5.25pt; text-align:left; line-height:normal; widows:2; orphans:2; padding-left:8.4pt; font-family:serif; font-size:10pt; ">
          <span style="font-size:13.5pt">Retain any personal information about you that was collected for a single
            one-time transaction if, in the ordinary course of business, that information about you is not
            retained;</span>
        </li>
        <li
          style="margin-left:20.1pt; margin-bottom:5.25pt; text-align:left; line-height:normal; widows:2; orphans:2; padding-left:8.4pt; font-family:serif; font-size:10pt; ">
          <span style="font-size:13.5pt">Reidentify or otherwise link any data that, in the ordinary course of
            business,
            is not maintained in a manner that would be considered personal information.</span>
        </li>
      </ul>
      <h2
        style="margin-top:0pt; margin-bottom:0pt; page-break-inside:avoid; page-break-after:avoid; line-height:108%; font-size:13pt; ">
        <span style="font-weight:normal">Deletion Request Rights</span>
      </h2>
      <p
        style="margin-top:14pt; margin-bottom:14pt; text-align:left; line-height:normal; widows:2; orphans:2; font-size:13.5pt; ">
        <span style="font-family:'Segoe UI'">You have the right to request that Company delete any of your personal
          information that we collected from you and retained, subject to certain exceptions.</span><span
          style="font-family:'Segoe UI'">&#xa0;</span><span style="font-family:'Segoe UI'"> We may deny your
          deletion
          request if retaining the personal information is necessary for us or our service provider(s) to:</span>
      </p>
      <p
        style="margin-top:14pt; margin-bottom:14pt; text-align:left; line-height:normal; widows:2; orphans:2; font-size:13.5pt; ">
        <span style="font-family:'Segoe UI'">1. Complete the transaction for which we collected the personal
          information,
          provide a good or service that you requested, take actions reasonably anticipated within the context of
          our
          ongoing business relationship with you, or otherwise perform our contract with you.</span>
      </p>
      <p
        style="margin-top:14pt; margin-bottom:14pt; text-align:left; line-height:normal; widows:2; orphans:2; font-size:13.5pt; ">
        <span style="font-family:'Segoe UI'">2. Detect security incidents, protect against malicious, deceptive,
          fraudulent,
          or illegal activity, or prosecute those responsible for such activities.</span>
      </p>
      <p
        style="margin-top:14pt; margin-bottom:14pt; text-align:left; line-height:normal; widows:2; orphans:2; font-size:13.5pt; ">
        <span style="font-family:'Segoe UI'">3. Debug products to identify and repair errors that impair existing
          intended
          functionality.</span>
      </p>
      <p
        style="margin-top:14pt; margin-bottom:14pt; text-align:left; line-height:normal; widows:2; orphans:2; font-size:13.5pt; ">
        <span style="font-family:'Segoe UI'">4. Exercise free speech, ensure the right of another consumer to
          exercise
          their
          free speech rights, or exercise another right provided for by law.</span>
      </p>
      <p
        style="margin-top:14pt; margin-bottom:14pt; text-align:left; line-height:normal; widows:2; orphans:2; font-size:13.5pt; ">
        <span style="font-family:'Segoe UI'">5. Comply with the California Electronic Communications Privacy Act
          (Cal.
          Penal
          Code § 1546 et. seq.).</span>
      </p>
      <p
        style="margin-top:14pt; margin-bottom:14pt; text-align:left; line-height:normal; widows:2; orphans:2; font-size:13.5pt; ">
        <span style="font-family:'Segoe UI'">6. Engage in public or peer-reviewed scientific, historical, or
          statistical
          research in the public interest that adheres to all other applicable ethics and privacy laws, when the
          information’s deletion may likely render impossible or seriously impair the research’s achievement, if
          you
          previously provided informed consent.</span>
      </p>
      <p
        style="margin-top:14pt; margin-bottom:14pt; text-align:left; line-height:normal; widows:2; orphans:2; font-size:13.5pt; ">
        <span style="font-family:'Segoe UI'">7. Enable solely internal uses that are reasonably aligned with
          consumer
          expectations based on your relationship with us.</span>
      </p>
      <p
        style="margin-top:14pt; margin-bottom:14pt; text-align:left; line-height:normal; widows:2; orphans:2; font-size:13.5pt; ">
        <span style="font-family:'Segoe UI'">8. Comply with a legal obligation.</span>
      </p>
      <p
        style="margin-top:14pt; margin-bottom:14pt; text-align:left; line-height:normal; widows:2; orphans:2; font-size:13.5pt; ">
        <span style="font-family:'Segoe UI'">9. Make other internal and lawful uses of that information that are
          compatible
          with the context in which you provided it.</span>
      </p>
      <h2
        style="margin-top:2pt; margin-bottom:0pt; page-break-inside:avoid; page-break-after:avoid; line-height:108%; font-size:13pt; ">
        <span style="font-family:'Segoe UI'; font-weight:normal">Children Under 16</span>
      </h2>
      <p
        style="margin-top:14pt; margin-bottom:14pt; text-align:left; line-height:normal; widows:2; orphans:2; font-size:13.5pt; ">
        <span style="font-family:'Segoe UI'">We do not sell the personal information of consumers we actually know
          are
          less
          than 16 years of age, unless we receive affirmative authorization (the “right to opt-in”) from either
          the
          consumer who is between 13 and 16 years of age, or the parent or guardian of a consumer less than 13
          years
          of
          age.</span><span style="font-family:'Segoe UI'">&#xa0;</span><span
          style="font-family:'Segoe UI'">&#xa0;</span>
      </p>
      <h2
        style="margin-top:2pt; margin-bottom:0pt; page-break-inside:avoid; page-break-after:avoid; line-height:108%; font-size:13pt; ">
        <span style="font-family:'Segoe UI'; font-weight:normal">No Discrimination</span>
      </h2>
      <p
        style="margin-top:14pt; margin-bottom:14pt; text-align:left; line-height:normal; widows:2; orphans:2; font-size:13.5pt; ">
        <span style="font-family:'Segoe UI'">We will not discriminate against you for exercising any of your CCPA
          rights.</span><span style="font-family:'Segoe UI'">&#xa0;</span><span style="font-family:'Segoe UI'">
          Unless
          permitted by the CCPA, we will not:</span>
      </p>
      <ul type="disc" style="margin:0pt; padding-left:0pt">
        <li
          style="margin-left:20.1pt; margin-bottom:5.25pt; text-align:left; line-height:normal; widows:2; orphans:2; padding-left:8.4pt; font-family:serif; font-size:10pt; ">
          <span style="font-size:13.5pt">Deny you goods or services;</span>
        </li>
        <li
          style="margin-left:20.1pt; margin-bottom:5.25pt; text-align:left; line-height:normal; widows:2; orphans:2; padding-left:8.4pt; font-family:serif; font-size:10pt; ">
          <span style="font-size:13.5pt">Charge you different prices or rates for goods or services, including
            through
            granting discounts or other benefits, or imposing penalties;</span>
        </li>
        <li
          style="margin-left:20.1pt; margin-bottom:5.25pt; text-align:left; line-height:normal; widows:2; orphans:2; padding-left:8.4pt; font-family:serif; font-size:10pt; ">
          <span style="font-size:13.5pt">Provide you a different level or quality of goods or services; or</span>
        </li>
        <li
          style="margin-left:20.1pt; margin-bottom:5.25pt; text-align:left; line-height:normal; widows:2; orphans:2; padding-left:8.4pt; font-family:serif; font-size:10pt; ">
          <span style="font-size:13.5pt">Suggest that you may receive a different price or rate for goods or
            services
            or a
            different level or quality of goods or services.</span>
        </li>
      </ul>
      <h2
        style="margin-top:2pt; margin-bottom:0pt; page-break-inside:avoid; page-break-after:avoid; line-height:108%; font-size:13pt; ">
        <span style="font-family:'Segoe UI'; font-weight:normal">Exercising Your Rights</span>
      </h2>
      <p
        style="margin-top:14pt; margin-bottom:14pt; text-align:left; line-height:normal; widows:2; orphans:2; font-size:13.5pt; ">
        <span style="font-family:'Segoe UI'">To exercise the access, disclosure and deletion rights described above,
          please
          submit a verifiable request to us by emailing</span><span style="font-family:'Segoe UI'">&#xa0;</span><a
          href="mailto:webmaster@thehollywoodgossip.com" style="text-decoration:none"><span
            style="color:#fff">webmaster@thehollywoodgossip.com</span></a><span style="font-family:'Segoe UI'">,
          Attn: CCPA.</span>
      </p>
      <p
        style="margin-top:14pt; margin-bottom:14pt; text-align:left; line-height:normal; widows:2; orphans:2; font-size:13.5pt; ">
        <span style="font-family:'Segoe UI'">You may only make a verifiable request for access or disclosure twice
          within a
          twelve (12) month period.</span><span style="font-family:'Segoe UI'">&#xa0;</span><span
          style="font-family:'Segoe UI'"> You may also make a verifiable consumer request on behalf of your minor
          child.
          The verifiable consumer request must:</span>
      </p>
      <ul type="disc" style="margin:0pt; padding-left:0pt">
        <li
          style="margin-left:20.1pt; margin-bottom:5.25pt; text-align:left; line-height:normal; widows:2; orphans:2; padding-left:8.4pt; font-family:serif; font-size:10pt; ">
          <span style="font-size:13.5pt">Provide sufficient information that allows us to reasonably verify you
            are
            the
            person about whom we collected personal information or an authorized representative.</span>
        </li>
        <li
          style="margin-left:20.1pt; margin-bottom:5.25pt; text-align:left; line-height:normal; widows:2; orphans:2; padding-left:8.4pt; font-family:serif; font-size:10pt; ">
          <span style="font-size:13.5pt">Describe your request with sufficient detail that allows us to properly
            understand, evaluate, and respond to it.</span>
        </li>
      </ul>
      <p
        style="margin-top:14pt; margin-bottom:14pt; text-align:left; line-height:normal; widows:2; orphans:2; font-size:13.5pt; ">
        <span style="font-family:'Segoe UI'">To respond to your request or provide you with personal information,
          Company
          must verify your identity or your authority to make the request.</span><span
          style="font-family:'Segoe UI'">&#xa0;</span><span style="font-family:'Segoe UI'">We will only use
          personal
          information provided in a verifiable request to verify the requestor’s identity or authority to make the
          request.</span><span style="font-family:'Segoe UI'">&#xa0;</span><span style="font-family:'Segoe UI'">For
          instructions on exercising sale opt-out rights, please refer to the Do Not Sell My Personal Information
          link
          on
          each page of this Website.</span><span style="font-family:'Segoe UI'">&#xa0;</span>
      </p>
      <p
        style="margin-top:14pt; margin-bottom:14pt; text-align:left; line-height:normal; widows:2; orphans:2; font-size:13.5pt; ">
        <span style="font-family:'Segoe UI'">Once you make an opt-out request, we will wait at least twelve (12)
          months
          before asking you to reauthorize personal information sales.</span><span
          style="font-family:'Segoe UI'">&#xa0;</span><span style="font-family:'Segoe UI'">However, you may change
          your
          mind and opt back in to personal information sales at any time by e-mailing us at</span><span
          style="font-family:'Segoe UI'">&#xa0;</span><a href="mailto:webmaster@thehollywoodgossip.com"
          style="text-decoration:none"><span style="color:#fff">webmaster@thehollywoodgossip.com</span></a><span
          style="font-family:'Segoe UI'">.</span><span style="font-family:'Segoe UI'">&#xa0;</span><span
          style="font-family:'Segoe UI'">Consumers who opt-in to personal information sales may opt-out of future
          sales at
          any time.</span>
      </p>
      <h2
        style="margin-top:2pt; margin-bottom:0pt; page-break-inside:avoid; page-break-after:avoid; line-height:108%; font-size:13pt; ">
        <span style="font-family:'Segoe UI'; font-weight:normal">Timing</span>
      </h2>
      <p
        style="margin-top:14pt; margin-bottom:14pt; text-align:left; line-height:normal; widows:2; orphans:2; font-size:13.5pt; ">
        <span style="font-family:'Segoe UI'">We will respond to a verifiable consumer request within forty-five (45)
          days of
          its receipt.</span><span style="font-family:'Segoe UI'">&#xa0;</span><span style="font-family:'Segoe UI'">If
          we
          require more time (up to ninety (90) days), we will inform you of the reason and extension period in
          writing.</span><span style="font-family:'Segoe UI'">&#xa0;</span><span style="font-family:'Segoe UI'">We
          will
          deliver our written response by mail or electronically, at your option. Any disclosures we provide will
          only
          cover the twelve (12) month period preceding the verifiable request’s receipt.</span><span
          style="font-family:'Segoe UI'">&#xa0;</span><span style="font-family:'Segoe UI'">The response we provide
          will
          also explain the reasons we cannot comply with a request, if applicable.</span>
      </p>
      <h1
        style="margin-top:14pt; margin-bottom:14pt; text-align:left; line-height:normal; widows:2; orphans:2; font-size:18pt; ">
        <span style="font-family:'Segoe UI'">SENSITIVE PERSONAL INFORMATION</span>
      </h1>
      <p
        style="margin-top:14pt; margin-bottom:14pt; text-align:left; line-height:normal; widows:2; orphans:2; font-size:13.5pt; ">
        <span style="font-family:'Segoe UI'">At no time should you submit sensitive personal information to the
          Website.
          This includes your social security number, information regarding race or ethnic origin, political
          opinions,
          religious beliefs, health information, criminal background, or trade union memberships. If you elect to
          submit
          such information to us, it will be subject to this Privacy Policy.</span><span
          style="font-family:'Segoe UI'">&#xa0;</span>
      </p>
      <h1
        style="margin-top:14pt; margin-bottom:14pt; text-align:left; line-height:normal; widows:2; orphans:2; font-size:18pt; ">
        <span style="font-family:'Segoe UI'">CHILDREN’S INFORMATION</span>
      </h1>
      <p
        style="margin-top:14pt; margin-bottom:14pt; text-align:left; line-height:normal; widows:2; orphans:2; font-size:13.5pt; ">
        <span style="font-family:'Segoe UI'">The Website does not knowingly collect any personally identifiable
          information
          from children under the age of 16. If a parent or guardian believes that the Website has personally
          identifiable
          information of a child under the age of 16 in its database, please contact us immediately at</span><span
          style="font-family:'Segoe UI'">&#xa0;</span>laminehached@gmail.com<span
          style="font-family:'Segoe UI'">&#xa0;</span><span style="font-family:'Segoe UI'">and we will use our
          best
          efforts to promptly remove such information from our records.</span>
      </p>
      <h1
        style="margin-top:14pt; margin-bottom:14pt; text-align:left; line-height:normal; widows:2; orphans:2; font-size:18pt; ">
        <span style="font-family:'Segoe UI'">LINKS TO OTHER WEBSITES</span>
      </h1>
      <p
        style="margin-top:14pt; margin-bottom:14pt; text-align:left; line-height:normal; widows:2; orphans:2; font-size:13.5pt; ">
        <span style="font-family:'Segoe UI'">This Privacy Policy applies only to the Website. The Website may
          contain
          links
          to other websites not operated or controlled by Company (the “Third-Party Sites”). The policies and
          procedures
          described here do not apply to the Third-Party Sites. The links from the Website do not imply that
          Company
          endorses or has reviewed the Third-Party Sites. We suggest contacting those sites directly for
          information
          on
          their privacy policies.</span>
      </p>
      <h1
        style="margin-top:14pt; margin-bottom:14pt; text-align:left; line-height:normal; widows:2; orphans:2; font-size:18pt; ">
        <span style="font-family:'Segoe UI'">OTHER TERMS AND CONDITIONS</span>
      </h1>
      <p
        style="margin-top:14pt; margin-bottom:14pt; text-align:left; line-height:normal; widows:2; orphans:2; font-size:13.5pt; ">
        <span style="font-family:'Segoe UI'">Your access to and use of the Website may also be subject to any
          separate
          agreements or terms and conditions you have signed or agreed to with Company.</span><span
          style="font-family:'Segoe UI'">&#xa0;</span><span style="font-family:'Segoe UI'">Please refer to those
          agreements as needed.</span>
      </p>
      <p
        style="margin-top:14pt; margin-bottom:14pt; text-align:left; line-height:normal; widows:2; orphans:2; font-size:13.5pt; ">
        <span style="font-family:'Segoe UI'">Regardless of any other provision in this Privacy Policy, we reserve
          the
          right
          to disclose any personally identifiable or non-personally identifiable information about you to: (a)
          fulfill
          a
          government request; (b) conform with the requirements of the law or legal process; (c) protect or defend
          our
          legal rights or property, our Website, or other users; or (d) protect, in an emergency, the health and
          safety of
          our customers or the general public. This includes exchanging information with third parties and
          organizations
          in connection with credit risk reduction and fraud protection.</span>
      </p>
      <h2
        style="margin-top:2pt; margin-bottom:0pt; page-break-inside:avoid; page-break-after:avoid; line-height:108%; font-size:13pt; ">
        <span style="font-family:'Segoe UI'; font-weight:normal">Changes to Google AdSense Privacy Policy</span>
      </h2>
      <p
        style="margin-top:14pt; margin-bottom:14pt; text-align:left; line-height:normal; widows:2; orphans:2; font-size:13.5pt; ">
        <span style="font-family:'Segoe UI'">The Website may change from time to time. As a result, at times it may
          be
          necessary for Company to make changes to this Privacy Policy. Company reserves the right to update or
          modify
          this Privacy Policy at any time. Please review this policy periodically, and especially before you
          provide
          any
          Personal Data. Your continued use of the Website after any changes or revisions to this Privacy Policy
          shall
          indicate your agreement with the terms of such revised Privacy Policy.</span>
      </p>
      <h3
        style="margin-top:2pt; margin-bottom:0pt; page-break-inside:avoid; page-break-after:avoid; line-height:108%; font-size:12pt; ">
        <span style="font-family:'Segoe UI'; font-weight:normal">Google AdSense Programmatic Advertising (Ver
          1.1)</span>
      </h3>
      <p
        style="margin-top:14pt; margin-bottom:14pt; text-align:left; line-height:normal; widows:2; orphans:2; font-size:13.5pt; ">
        <span style="font-family:'Segoe UI'">The Website works with Google AdSense to manage third-party
          interest-based
          advertising appearing on the Website. Google AdSense serves content and advertisements when you visit
          the
          Website, which may use first and third-party cookies. A cookie is a small text file which is sent to
          your
          computer or mobile device (referred to in this policy as a “device”) by the web server so that a website
          can
          remember some information about your browsing activity on the Website.</span>
      </p>
      <p
        style="margin-top:14pt; margin-bottom:14pt; text-align:left; line-height:normal; widows:2; orphans:2; font-size:13.5pt; ">
        <span style="font-family:'Segoe UI'">First party cookies are created by the website that you are visiting. A
          third-party cookie is frequently used in behavioral advertising and analytics and is created by a domain
          other
          than the website you are visiting. Third-party cookies, tags, pixels, beacons and other similar
          technologies
          (collectively, “Tags”) may be placed on the Website to monitor interaction with advertising content and
          to
          target and optimize advertising. Each internet browser has functionality so that you can block both
          first
          and
          third-party cookies and clear your browser’s cache. The “help” feature of the menu bar on most browsers
          will
          tell you how to stop accepting new cookies, how to receive notification of new cookies, how to disable
          existing
          cookies and how to clear your browser’s cache. For more information about cookies and how to disable
          them,
          you
          can consult the information at</span><span style="font-family:'Segoe UI'">&#xa0;</span><a
          href="https://www.allaboutcookies.org/manage-cookies/" target="_blank" style="text-decoration:none"><span
            style="color:#fff">All About Cookies</span></a><span style="font-family:'Segoe UI'">.</span>
      </p>
      <p
        style="margin-top:14pt; margin-bottom:14pt; text-align:left; line-height:normal; widows:2; orphans:2; font-size:13.5pt; ">
        <span style="font-family:'Segoe UI'">Without cookies you may not be able to take full advantage of the
          Website
          content and features. Please note that rejecting cookies does not mean that you will no longer see ads
          when
          you
          visit our Site. In the event you opt-out, you will still see non-personalized advertisements on the
          Website.</span>
      </p>
      <p
        style="margin-top:14pt; margin-bottom:14pt; text-align:left; line-height:normal; widows:2; orphans:2; font-size:13.5pt; ">
        <span style="font-family:'Segoe UI'">The Website collects the following data using a cookie when serving
          personalized ads:</span>
      </p>
      <ul type="disc" style="margin:0pt; padding-left:0pt">
        <li
          style="margin-left:20.1pt; margin-bottom:5.25pt; text-align:left; line-height:normal; widows:2; orphans:2; padding-left:8.4pt; font-family:serif; font-size:10pt; ">
          <span style="font-size:13.5pt">IP Address</span>
        </li>
        <li
          style="margin-left:20.1pt; margin-bottom:5.25pt; text-align:left; line-height:normal; widows:2; orphans:2; padding-left:8.4pt; font-family:serif; font-size:10pt; ">
          <span style="font-size:13.5pt">Operating System type</span>
        </li>
        <li
          style="margin-left:20.1pt; margin-bottom:5.25pt; text-align:left; line-height:normal; widows:2; orphans:2; padding-left:8.4pt; font-family:serif; font-size:10pt; ">
          <span style="font-size:13.5pt">Operating System version</span>
        </li>
        <li
          style="margin-left:20.1pt; margin-bottom:5.25pt; text-align:left; line-height:normal; widows:2; orphans:2; padding-left:8.4pt; font-family:serif; font-size:10pt; ">
          <span style="font-size:13.5pt">Device Type</span>
        </li>
        <li
          style="margin-left:20.1pt; margin-bottom:5.25pt; text-align:left; line-height:normal; widows:2; orphans:2; padding-left:8.4pt; font-family:serif; font-size:10pt; ">
          <span style="font-size:13.5pt">Language of the website</span>
        </li>
        <li
          style="margin-left:20.1pt; margin-bottom:5.25pt; text-align:left; line-height:normal; widows:2; orphans:2; padding-left:8.4pt; font-family:serif; font-size:10pt; ">
          <span style="font-size:13.5pt">Web browser type</span>
        </li>
        <li
          style="margin-left:20.1pt; margin-bottom:5.25pt; text-align:left; line-height:normal; widows:2; orphans:2; padding-left:8.4pt; font-family:serif; font-size:10pt; ">
          <span style="font-size:13.5pt">Email (in hashed form)</span>
        </li>
      </ul>
      <p
        style="margin-top:14pt; margin-bottom:14pt; text-align:left; line-height:normal; widows:2; orphans:2; font-size:13.5pt; ">
        <span style="font-family:'Segoe UI'">Google AdSense Partners (companies listed below with whom Google
          AdSense
          shares
          data) may also use this data to link to other end user information the partner has independently
          collected
          to
          deliver targeted advertisements. Google AdSense Partners may also separately collect data about end
          users
          from
          other sources, such as advertising IDs or pixels, and link that data to data collected from Google
          AdSense</span><span style="font-family:'Segoe UI'; font-size:12pt"> </span><span
          style="font-family:'Segoe UI'">publishers in order to provide interest-based advertising across your
          online
          experience, including devices, browsers and apps. This data includes usage data, cookie information,
          device
          information, information about interactions between users and advertisements and websites, geolocation
          data,
          traffic data, and information about a visitor’s referral source to a particular website. Google AdSense
          Partners
          may also create unique IDs to create audience segments, which are used to provide targeted
          advertising.</span>
      </p>
      <p
        style="margin-top:14pt; margin-bottom:14pt; text-align:left; line-height:normal; widows:2; orphans:2; font-size:13.5pt; ">
        <em>Last updated: February 24, 2024.</em>
      </p>

    </div>
  </b-container>
</template>

<script>
export default {
  name: "PrivacyPolicyView",
  created() { },
  data() {
    return {};
  },
  props: {},
  methods: {},
};
</script>

<style lang="scss" scoped></style>