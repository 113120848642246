<template>
    <b-container fluid="md" class="mt-1" style="max-width: 900px">
        <b-row cols="1" cols-sm="1" cols-md="2" cols-lg="2">
            <b-col
                v-for="(item, idx) in gameList"
                :key="idx"
                class="px-3 my-3"
                @click="onGameClicked(item)"
            >
                <b-card
                    style="
                        max-width: 25rem;
                        height: 8rem;
                        box-shadow: 2px 3px 5px #222;
                    "
                    class=""
                >
                    <b-row>
                        <b-col style="padding-right: 0px">
                            <b-card-img-lazy
                                :src="item.icon"
                                :alt="item.name"
                                rounded
                                style="height: 8rem; object-fit: cover"
                            ></b-card-img-lazy>
                        </b-col>
                        <b-col style="padding-left: 5px; text-align: left">
                            <div
                                class="mt-1 lines-one"
                                style="font-weight: 900"
                            >
                                {{ getName(item.name) }}
                            </div>
                            <div class="mt-1 lines-two" style="">
                                {{ getDesc(item.name) }}
                            </div>
                        </b-col>
                    </b-row>
                </b-card>
            </b-col>
        </b-row>
        <infinite-loading
            :identifier="infiniteId"
            @infinite="getGameList"
        ></infinite-loading>
    </b-container>
</template>

<script>
import gameApi from "../api/gameApi.js";

export default {
    name: "GameList",
    created() {},
    data() {
        return {
            gameList: [],
            page: 0,
            isLoading: false,
            pageSize: 20,
            infiniteId: +new Date(),
            names:
            {
                "Zombies Cant Jump 2": {
                    "name": "Undead Tactic 2",
                    "desc": "Undead Tactic 2 challenges you to outsmart the undead with new levels of strategy. Evade the relentless horde that lacks the ability to jump, and survive the night."
                },
                "Zombies Cant Jump 1": {
                    "name": "Ghoul Evasion 1",
                    "desc": "In Ghoul Evasion 1, you are immersed in a world where ghouls roam but cannot leap over barriers. Utilize your agility to dodge them and master the art of outmaneuvering."
                },
                "Yeti Sensation": {
                    "name": "Yeti's Mountain Quest",
                    "desc": "Embark on Yeti's Mountain Quest and experience the might of a legendary yeti. Overcome obstacles and uncover the hidden secrets of the snowy peaks."
                },
                "The Maze": {
                    "name": "Labyrinthine Escape",
                    "desc": "Labyrinthine Escape is a brain-teasing puzzler that requires you to navigate through a maze riddled with traps. Use your intellect to find the path to freedom."
                },
                "Super Neon Ball": {
                    "name": "Neon Orb Adventure",
                    "desc": "Control a luminous orb in Neon Orb Adventure, bouncing through levels illuminated by neon lights. Master the light reflections to conquer vibrant challenges."
                },
                "Super Knight": {
                    "name": "Knightly Valor",
                    "desc": "Knightly Valor casts you as a formidable knight on a quest to save the kingdom. Battle enemies with enhanced skills and arms, ready for the ultimate battle."
                },
                "Stickman Vex 4": {
                    "name": "Stickman's Perilous Pursuit 4",
                    "desc": "Stickman's Perilous Pursuit 4 offers a new set of complex puzzles and intense action. Guide the stickman through a world of chaos to complete each level."
                },
                "Stephen Karsch": {
                    "name": "Steven's Expedition",
                    "desc": "Steven's Expedition follows the daring escapades of an explorer as he uncovers ancient mysteries and solves complex puzzles, revealing secrets of the past."
                },
                "Shmupnage": {
                    "name": "Space Combat Frenzy",
                    "desc": "Space Combat Frenzy is a fast-paced shoot 'em up where you pilot a spaceship through enemy waves. Upgrade your weapons and dodge attacks in this space battle."
                },
                "Rooster Warrior": {
                    "name": "Rooster's Defense",
                    "desc": "In Rooster's Defense, you take on the role of a gallant rooster, defending your home from invaders with an arsenal of weapons and strategic defenses."
                },
                "Rally Racer": {
                    "name": "Off-Road Velocity",
                    "desc": "Off-Road Velocity is an exhilarating rally racing game with off-road vehicles. Race across varied landscapes and dominate the tracks to become the champion."
                },
                "Pinnacle Racer": {
                    "name": "Mountain Summit Sprint",
                    "desc": "Mountain Summit Sprint is a high-speed racing game set in the mountains. Race against time and rivals on treacherous roads to be the first to the peak."
                },
                "Owl House": {
                    "name": "Owl's Dwelling Quest",
                    "desc": "Owl's Dwelling Quest is a heartwarming puzzle game where you assist an owl in constructing its ideal home. Gather resources and solve puzzles for a cozy retreat."
                },
                "Nin Jago Braw": {
                    "name": "Ninja Duels",
                    "desc": "Ninja Duels is a multiplayer showdown where players face off as ninjas. Employ stealth, agility, and cunning to outsmart and defeat your adversaries."
                },
                "Ninja Boy 2": {
                    "name": "Shadow Assassin 2",
                    "desc": "Shadow Assassin 2 continues the journey of a skilled ninja, introducing new abilities and challenges. Navigate through shadows, master combat, and explore the ancient ninja world."
                },
                "Ninja Boy 1": {
                    "name": "Silent Blade",
                    "desc": "Silent Blade is a stealth-action game where you embody a samurai on a covert mission. Use your speed and wit to evade foes or strike with lethal precision."
                },
                "Mystery Chase": {
                    "name": "Detective Enigma",
                    "desc": "Detective Enigma is a gripping detective game brimming with enigmas. Trace clues, question suspects, and decipher puzzles to solve a series of baffling crimes."
                },
                "Knight Rider": {
                    "name": "Chivalric Quest",
                    "desc": "Chivalric Quest is a medieval action-adventure where you play a knight embarking on a noble mission. Engage in combat, defend your kingdom, and live by chivalric virtues."
                },
                "King Soldier": {
                    "name": "Realm Defender",
                    "desc": "Realm Defender is a strategic game of kingdom protection. Command your troops, build defenses, and outthink your enemies to secure your realm."
                },
                "Keep Out": {
                    "name": "Intruder Alert",
                    "desc": "Intruder Alert challenges you to secure your territory against intruders. Set up traps and barriers, and use strategy to keep trespassers at bay."
                },
                "Gunmach": {
                    "name": "Robo Battle Arena",
                    "desc": "Robo Battle Arena is a dynamic robot fighting game. Customize your bot, arm it with powerful weapons, and compete in intense arena battles."
                },
                "Epic War 2": {
                    "name": "Epic Conquest 2",
                    "desc": "Epic Conquest 2 is a grand strategy war game. Lead your forces, form alliances, and fight legendary battles to shape the destiny of your realm."
                },
                "Epic War 1": {
                    "name": "Heroic Battles 1",
                    "desc": "Heroic Battles 1 is a tactical war simulation. Command your troops with wisdom, devise strategies, and lead to victory in heroic conflicts."
                },
                "Drift Rally Champion": {
                    "name": "Slide Master",
                    "desc": "Slide Master is a racing game that glorifies the skill of drifting. Master the drift on different tracks to become the ultimate drift racing champion."
                },
                "Diseviled Stolen Kingdom": {
                    "name": "Cursed Kingdom Quest",
                    "desc": "Cursed Kingdom Quest is a fantasy adventure where you seek to reclaim a stolen kingdom. Engage in battles, solve riddles, and restore the kingdom's former splendor."
                },
                "Dino Rage": {
                    "name": "Prehistoric Fury",
                    "desc": "Prehistoric Fury takes you back to the dinosaur age. Experience the primal power and survival skills of these ancient creatures in an action-packed adventure."
                },
                "Defend The Sand Castle": {
                    "name": "Beach Fortress Defense",
                    "desc": "Beach Fortress Defense is a strategic game where you defend your sand castle from invaders. Construct defenses and strategize to withstand the onslaught."
                },
                "Cyber Champions Arena": {
                    "name": "Cyber Gladiators",
                    "desc": "Cyber Gladiators is a futuristic combat game set in a virtual arena. Choose your cyber warrior, master their skills, and fight against opponents in a high-tech battle."
                },
                "Cold Station": {
                    "name": "Frost Outpost",
                    "desc": "Frost Outpost is a survival game set in a frozen tundra. Gather resources, build shelter, and endure the harsh cold in this challenging experience."
                },
                "Black Panther Jungle Pursuit": {
                    "name": "Wild Panther Hunt",
                    "desc": "Wild Panther Hunt is an action-adventure game where you track the elusive black panther. Experience the thrill of the pursuit and the wilderness's beauty."
                },
                "Ben 10 World Rescue": {
                    "name": "Alien Hero's Mission",
                    "desc": "Alien Hero's Mission follows Ben Tennyson as he uses the Omnitrix to transform into various aliens and save the world. Join the action-packed adventure of an alien hero."
                },
                "Battle Robot Wolf Age": {
                    "name": "Wolf Mech Combat",
                    "desc": "Wolf Mech Combat is a mech battle game set in a futuristic setting. Pilot a wolf-like mech, engage in fierce battles, and dominate the field with tactical skills."
                },
                "Battle Robot TRex Age": {
                    "name": "T-Rex Mech Wars",
                    "desc": "T-Rex Mech Wars brings the might of the T-Rex to mech battles. Control a T-Rex inspired mech and fight for supremacy in this prehistoric-themed combat game."
                },
                "Battle Robot Samurai Age": {
                    "name": "Samurai Mech Duel",
                    "desc": "Samurai Mech Duel merges samurai combat with mech technology. Wield a sword in your mech and engage in duels that reflect the samurai spirit."
                },
                "Archery Training": {
                    "name": "Arrow Mastery",
                    "desc": "Arrow Mastery is an archery training simulation that helps you refine your archery skills. Improve your aim, strength, and technique through various training exercises."
                },
                "13 Nights": {
                    "name": "Twilight Survival",
                    "desc": "Twilight Survival is a horror-adventure game where you endure thirteen spine-chilling nights. Face supernatural entities, solve puzzles, and unveil the haunting truth."
                }
            },
        };
    },
    props: {
        gameType: {
            type: Number,
            default: 0,
        },
        gameName: {
            type: String,
            default: "",
        },
        hot: {
            type: Number,
            default: -1,
        },
        recommend: {
            type: Number,
            default: -1,
        },
        excludeGameId: {
            type: Number,
            default: -1,
        },
    },
    watch: {
        gameType() {
            this.refresh();
        },
    },
    methods: {
        refresh() {
            this.isLoading = false;
            this.page = 0;
            this.gameList = [];
            this.infiniteId += 1;
        },
        getName(name) {
            if (this.names[name]) {
                return this.names[name].name;
            } else {
                return name;
            }
        },
        getDesc(name) {
          if (this.names[name]) {
                return this.names[name].desc;
            } else {
                return "";
            }
        },
        getGameList($state) {
            if (!this.isLoading) {
                this.isLoading = true;

                console.log(
                    "getGameList: ",
                    this.page,
                    this.pageSize,
                    this.gameType,
                    this.gameName,
                    this.hot,
                    this.recommend
                );

                gameApi
                    .getGameList(
                        this.page,
                        this.pageSize,
                        this.gameType,
                        this.gameName,
                        this.hot,
                        this.recommend
                    )
                    .then((response) => {
                        console.log("response", response);
                        this.isLoading = false;

                        if (
                            response.status === 200 &&
                            response.data.code == 0
                        ) {
                            if (response.data.data.items.length == 0) {
                                $state.complete();
                                return;
                            }

                            var shuffledgamelist = this.shuffleArray(
                                response.data.data.items
                            );

                            this.gameList =
                                this.gameList.concat(shuffledgamelist);

                            //remove game from game list by game id
                            if (this.excludeGameId > 0) {
                                this.gameList = this.gameList.filter(
                                    (item) => item.id != this.excludeGameId
                                );
                            }

                            console.log("gameList: ", this.gameList);
                            this.page += 20;
                            $state.loaded();
                        } else {
                            console.error(
                                "request gameApi.getGameList error",
                                response
                            );
                            $state.complete();
                        }
                    });
            } else {
                $state.loaded();
            }
        },
        onGameClicked(item) {
            console.log("onGameClicked: ", item);
            this.$router
                .push({
                    name: "game",
                    params: {
                        gamename: this.getGameNameWithDash(item.name),
                        gameid: item.id,
                    },
                })
                .catch((err) => {
                    err;
                });
        },
        getGameName(str) {
            if (!str) {
                return;
            }
            return str.replace(/([a-z0-9])([A-Z0-9])/g, "$1 $2");
        },
        getGameNameWithDash(str) {
            // convert string with white space into array
            if (!str) {
                return;
            }
            return str.replaceAll(" ", "-").toLowerCase();
        },
        shuffleArray(array) {
            for (let i = array.length - 1; i > 0; i--) {
                const j = Math.floor(Math.random() * (i + 1));
                [array[i], array[j]] = [array[j], array[i]];
            }
            return array;
        },
    },
};
</script>

<style lang="scss" scoped>
.card-body {
    padding: 0;
    background-color: #6E2773;
}

.card-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    /* Optional: scale and crop the image to fill the element */
}

.lines-one {
    overflow: hidden; /* 隐藏溢出的内容 */
    text-overflow: ellipsis; /* 当内容溢出时显示省略号 */
    display: -webkit-box; /* 使用webkit的盒子模型 */
    -webkit-line-clamp: 1; /* 限制在两行以内 */
    -webkit-box-orient: vertical; /* 设置盒子的子元素垂直排列 */
    white-space: normal; /* 保持默认的空白符处理 */
}

.lines-two {
    overflow: hidden; /* 隐藏溢出的内容 */
    text-overflow: ellipsis; /* 当内容溢出时显示省略号 */
    display: -webkit-box; /* 使用webkit的盒子模型 */
    -webkit-line-clamp: 4; /* 限制在两行以内 */
    -webkit-box-orient: vertical; /* 设置盒子的子元素垂直排列 */
    white-space: normal; /* 保持默认的空白符处理 */
    font-size: 0.9rem;
}
</style>