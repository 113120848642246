<template>
  <div class="home">
    <GameList :gameType="0" :gameName="''" :hot="-1" :recommend="-1"></GameList>
  </div>
</template>

<script>
import gameApi from '../api/gameApi.js';
import GameList from '../components/GameList.vue';

export default {
  name: 'HomeView',
  components: {
    GameList,
  },
  data() {
    return {
      gameList: [],
      page: 0,
      isLoading: false,
      pageSize: 20,

    }
  },
  watch: {
    $route() {
      this.getGameList();
    },
  },
  mounted() {
    console.log('HomeView mounted');
    // this.getGameList();
  },
  methods: {
    getGameList($state) {
      if (!this.isLoading) {
        this.isLoading = true;
        gameApi.getGameList(this.page, this.pageSize, 0, '', -1, -1).then((response) => {
          console.log('response', response);
          if (response.status === 200 && response.data.code == 0) {

            if (response.data.data.items.length == 0) {
              $state.complete();
              return;
            }

            this.gameList = this.gameList.concat(response.data.data.items);
            console.log('gameList: ', this.gameList);
            this.page += 1;
            this.isLoading = false;
            $state.loaded();
          } else {
            console.log('request gameApi.getGameList error', response);
            this.isLoading = false;
            $state.loaded();
          }
        });
      }
    },
    onGameClicked(item) {
      console.log('onGameClicked: ', item);
      this.$router.push({ name: 'game', params: { gameid: item.id } }).catch(err => { err });
    },
    getGameName(str) {
      if (!str) {
        return
      }
      return str.replace(/([a-z0-9])([A-Z0-9])/g, '$1 $2')
    },
  },
}
</script>

<style scoped></style>