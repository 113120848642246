<template>
  <div>
    <div class="h3 mt-3">Search for: {{ $route.params.searchquery }}</div>
    <GameList ref="gameList" :gameName="$route.params.searchquery"></GameList>
  </div>
</template>

<script>
import GameList from '../components/GameList.vue';
export default {
  name: "SearchView",
  components: {
    GameList
  },
  watch: {
    $route() {
      this.$refs.gameList.refresh();
    },
  },
  mounted() {
    console.log('this.$route.params.searchquery: ', this.$route.params.searchquery);
  },
};
</script>

<style lang="scss" scoped></style>