<template>
  <b-container fluid style="max-width: 900px;">
    <b-row>
      <b-col class="px-0">
        <b-embed id="iframe_game" type="iframe" aspect="4by3" :src="gameurl" allowfullscreen scrolling='no' seamless
          frameborder='0' style='display:block; overflow:hidden; max-width: 900px;' class="mx-auto"></b-embed>
      </b-col>
    </b-row>
    <b-row align-h="between" align-v="center" class="bg-info py-2">
      <b-row align-v="center" class="ml-1" style="color:#fff;font-weight: 800;text-transform: uppercase;">
        <b-col>
          <b-img thumbnail :src="gameData.icon" style="height: 40px;"></b-img>
        </b-col>
        <b-col cols="auto" md="auto" class="px-0">
          <span>{{ gameData.name }}</span>
        </b-col>
      </b-row>
      <b-col cols="auto" md="auto">
        <b-button variant="primary" size="sm" @click="setIframeFullScreen">
          <b-icon-arrows-fullscreen></b-icon-arrows-fullscreen>
        </b-button>
      </b-col>
    </b-row>
    <b-row>
      <b-col class="px-0">
        <div class="mt-3">OTHER GAMES</div>
        <GameList ref="gameList" v-if="gameData.id" :excludeGameId="gameData.id"></GameList>
      </b-col>
    </b-row>
  </b-container>

  <!-- <b-embed type="iframe" aspect="21by9" :src="gameurl" allowfullscreen scrolling='no' seamless frameborder='0'
      style='display:block;overflow:hidden; max-width: 900px;' class="mx-auto"></b-embed> -->

  <!-- <iframe :src="gameurl" width="100%" height="400"
      v-bind="{ style: { transform: 'rotate(90deg)', transformOrigin: 'top left' } }"></iframe> -->
  <!-- <iframe :src="gameurl" ref="iframe"></iframe> -->
  <!-- <iframe id='iframe_game' :src="gameurl" webkitallowfullscreen='true' mozallowfullscreen='true' allowfullscreen='true'
      webkit-playsinline='true' scrolling='no' seamless frameborder='0' style='display:block;overflow:hidden;'></iframe> -->
</template>

<script>
import gameApi from '../api/gameApi.js';
import GameList from '../components/GameList.vue';

export default {
  name: "GamePlayView",
  components: {
    GameList,
  },
  data() {
    return {
      gameData: {},
      gameurl: ''
    };
  },
  mounted() {
    console.log('GamePlayView mounted');
    this.gameid = this.$route.params.gameid;
    this.getGameDetail();
  },
  methods: {
    getGameDetail() {
      gameApi.getGame(this.gameid).then((response) => {
        console.log('response', response);
        if (response.status === 200 && response.data.code == 0) {
          this.gameurl = response.data.data.url;
          console.log('gamePlayData: ', this.gameurl);
        } else {
          console.log('request gameApi.getGame error', response);
        }
      });
      gameApi.getGameInfo(this.gameid).then((response) => {
        console.log('response', response);
        if (response.status === 200 && response.data.code == 0) {
          this.gameData = response.data.data;
          console.log('gameData: ', this.gameData);
        } else {
          console.log('request gameApi.getGameInfo error', response);
        }
      });
    },
    setIframeFullScreen() {
      var iframe = document.getElementById('iframe_game');
      if (iframe.requestFullscreen) {
        iframe.requestFullscreen();
      } else if (iframe.mozRequestFullScreen) {
        iframe.mozRequestFullScreen();
      } else if (iframe.webkitRequestFullscreen) {
        iframe.webkitRequestFullscreen();
      } else if (iframe.msRequestFullscreen) {
        iframe.msRequestFullscreen();
      }
    },
    getGameName(str) {
      if (!str) {
        return
      }
      return str.replace(/([a-z0-9])([A-Z0-9])/g, '$1 $2')
    },
  },
};
</script>

<style lang="scss" scoped></style>