<template>
  <div>
    <b-card class="bg-secondary rounded-0">
      <b-card-img :src="gameData.icon" :alt="gameData.name"
        style="border: 3px solid #fff;box-shadow: 0 0 1px rgba(0,0,0,0.3); width:150px; height:150px;"></b-card-img>
      <b-card-body class="px-0" style="padding-bottom: 0;">
        <b-card-title title-tag="h4" style="color:#fff;font-weight: 800;text-transform: uppercase;">{{
        gameData.name
      }}</b-card-title>
        <b-card-text style="color: rgb(210 210 210); font-weight:500;">
          <b-icon icon="joystick" font-scale="1" variant="primary"></b-icon>
          {{ kFormatter(gameData.playNum) }} played
        </b-card-text>
        <!-- <b-button href="#" variant="primary">Play</b-button> -->
        <b-button variant="primary" style="width:250px;" @click="playGame()">
          <div class="btn-animation">Play</div>
        </b-button>

        <b-container fluid="md" class="mt-3 px-0" style="max-width: 900px;">
          <div v-if="gameData.desc" class="text-collapse-wrapper" style="color: rgb(210 210 210); font-weight:500;">
            <input id="exp1" class="exp" type="checkbox">
            <div class="text">
              <label class="btn" for="exp1"></label>
              {{ gameData.desc }}
            </div>
          </div>
        </b-container>

      </b-card-body>
    </b-card>
    <div class="mt-3">OTHER GAMES</div>
    <GameList ref="gameList" v-if="gameData.id" :excludeGameId="gameData.id"></GameList>
  </div>
</template>

<script>
// import { BIconHandThumbsUpFill } from 'bootstrap-vue';
import gameApi from '../api/gameApi.js';
import GameList from '../components/GameList.vue';

export default {
  name: "GameDetailView",
  components: {
    GameList
  },
  created() { },
  data() {
    return {
      gameid: 0,
      gameData: {},
      gamePlayData: {},
      dataLoaded: false,
      names: 
      {
                "Zombies Cant Jump 2": {
                    "name": "Undead Tactic 2",
                    "desc": "Undead Tactic 2 challenges you to outsmart the undead with new levels of strategy. Evade the relentless horde that lacks the ability to jump, and survive the night."
                },
                "Zombies Cant Jump 1": {
                    "name": "Ghoul Evasion 1",
                    "desc": "In Ghoul Evasion 1, you are immersed in a world where ghouls roam but cannot leap over barriers. Utilize your agility to dodge them and master the art of outmaneuvering."
                },
                "Yeti Sensation": {
                    "name": "Yeti's Mountain Quest",
                    "desc": "Embark on Yeti's Mountain Quest and experience the might of a legendary yeti. Overcome obstacles and uncover the hidden secrets of the snowy peaks."
                },
                "The Maze": {
                    "name": "Labyrinthine Escape",
                    "desc": "Labyrinthine Escape is a brain-teasing puzzler that requires you to navigate through a maze riddled with traps. Use your intellect to find the path to freedom."
                },
                "Super Neon Ball": {
                    "name": "Neon Orb Adventure",
                    "desc": "Control a luminous orb in Neon Orb Adventure, bouncing through levels illuminated by neon lights. Master the light reflections to conquer vibrant challenges."
                },
                "Super Knight": {
                    "name": "Knightly Valor",
                    "desc": "Knightly Valor casts you as a formidable knight on a quest to save the kingdom. Battle enemies with enhanced skills and arms, ready for the ultimate battle."
                },
                "Stickman Vex 4": {
                    "name": "Stickman's Perilous Pursuit 4",
                    "desc": "Stickman's Perilous Pursuit 4 offers a new set of complex puzzles and intense action. Guide the stickman through a world of chaos to complete each level."
                },
                "Stephen Karsch": {
                    "name": "Steven's Expedition",
                    "desc": "Steven's Expedition follows the daring escapades of an explorer as he uncovers ancient mysteries and solves complex puzzles, revealing secrets of the past."
                },
                "Shmupnage": {
                    "name": "Space Combat Frenzy",
                    "desc": "Space Combat Frenzy is a fast-paced shoot 'em up where you pilot a spaceship through enemy waves. Upgrade your weapons and dodge attacks in this space battle."
                },
                "Rooster Warrior": {
                    "name": "Rooster's Defense",
                    "desc": "In Rooster's Defense, you take on the role of a gallant rooster, defending your home from invaders with an arsenal of weapons and strategic defenses."
                },
                "Rally Racer": {
                    "name": "Off-Road Velocity",
                    "desc": "Off-Road Velocity is an exhilarating rally racing game with off-road vehicles. Race across varied landscapes and dominate the tracks to become the champion."
                },
                "Pinnacle Racer": {
                    "name": "Mountain Summit Sprint",
                    "desc": "Mountain Summit Sprint is a high-speed racing game set in the mountains. Race against time and rivals on treacherous roads to be the first to the peak."
                },
                "Owl House": {
                    "name": "Owl's Dwelling Quest",
                    "desc": "Owl's Dwelling Quest is a heartwarming puzzle game where you assist an owl in constructing its ideal home. Gather resources and solve puzzles for a cozy retreat."
                },
                "Nin Jago Braw": {
                    "name": "Ninja Duels",
                    "desc": "Ninja Duels is a multiplayer showdown where players face off as ninjas. Employ stealth, agility, and cunning to outsmart and defeat your adversaries."
                },
                "Ninja Boy 2": {
                    "name": "Shadow Assassin 2",
                    "desc": "Shadow Assassin 2 continues the journey of a skilled ninja, introducing new abilities and challenges. Navigate through shadows, master combat, and explore the ancient ninja world."
                },
                "Ninja Boy 1": {
                    "name": "Silent Blade",
                    "desc": "Silent Blade is a stealth-action game where you embody a samurai on a covert mission. Use your speed and wit to evade foes or strike with lethal precision."
                },
                "Mystery Chase": {
                    "name": "Detective Enigma",
                    "desc": "Detective Enigma is a gripping detective game brimming with enigmas. Trace clues, question suspects, and decipher puzzles to solve a series of baffling crimes."
                },
                "Knight Rider": {
                    "name": "Chivalric Quest",
                    "desc": "Chivalric Quest is a medieval action-adventure where you play a knight embarking on a noble mission. Engage in combat, defend your kingdom, and live by chivalric virtues."
                },
                "King Soldier": {
                    "name": "Realm Defender",
                    "desc": "Realm Defender is a strategic game of kingdom protection. Command your troops, build defenses, and outthink your enemies to secure your realm."
                },
                "Keep Out": {
                    "name": "Intruder Alert",
                    "desc": "Intruder Alert challenges you to secure your territory against intruders. Set up traps and barriers, and use strategy to keep trespassers at bay."
                },
                "Gunmach": {
                    "name": "Robo Battle Arena",
                    "desc": "Robo Battle Arena is a dynamic robot fighting game. Customize your bot, arm it with powerful weapons, and compete in intense arena battles."
                },
                "Epic War 2": {
                    "name": "Epic Conquest 2",
                    "desc": "Epic Conquest 2 is a grand strategy war game. Lead your forces, form alliances, and fight legendary battles to shape the destiny of your realm."
                },
                "Epic War 1": {
                    "name": "Heroic Battles 1",
                    "desc": "Heroic Battles 1 is a tactical war simulation. Command your troops with wisdom, devise strategies, and lead to victory in heroic conflicts."
                },
                "Drift Rally Champion": {
                    "name": "Slide Master",
                    "desc": "Slide Master is a racing game that glorifies the skill of drifting. Master the drift on different tracks to become the ultimate drift racing champion."
                },
                "Diseviled Stolen Kingdom": {
                    "name": "Cursed Kingdom Quest",
                    "desc": "Cursed Kingdom Quest is a fantasy adventure where you seek to reclaim a stolen kingdom. Engage in battles, solve riddles, and restore the kingdom's former splendor."
                },
                "Dino Rage": {
                    "name": "Prehistoric Fury",
                    "desc": "Prehistoric Fury takes you back to the dinosaur age. Experience the primal power and survival skills of these ancient creatures in an action-packed adventure."
                },
                "Defend The Sand Castle": {
                    "name": "Beach Fortress Defense",
                    "desc": "Beach Fortress Defense is a strategic game where you defend your sand castle from invaders. Construct defenses and strategize to withstand the onslaught."
                },
                "Cyber Champions Arena": {
                    "name": "Cyber Gladiators",
                    "desc": "Cyber Gladiators is a futuristic combat game set in a virtual arena. Choose your cyber warrior, master their skills, and fight against opponents in a high-tech battle."
                },
                "Cold Station": {
                    "name": "Frost Outpost",
                    "desc": "Frost Outpost is a survival game set in a frozen tundra. Gather resources, build shelter, and endure the harsh cold in this challenging experience."
                },
                "Black Panther Jungle Pursuit": {
                    "name": "Wild Panther Hunt",
                    "desc": "Wild Panther Hunt is an action-adventure game where you track the elusive black panther. Experience the thrill of the pursuit and the wilderness's beauty."
                },
                "Ben 10 World Rescue": {
                    "name": "Alien Hero's Mission",
                    "desc": "Alien Hero's Mission follows Ben Tennyson as he uses the Omnitrix to transform into various aliens and save the world. Join the action-packed adventure of an alien hero."
                },
                "Battle Robot Wolf Age": {
                    "name": "Wolf Mech Combat",
                    "desc": "Wolf Mech Combat is a mech battle game set in a futuristic setting. Pilot a wolf-like mech, engage in fierce battles, and dominate the field with tactical skills."
                },
                "Battle Robot TRex Age": {
                    "name": "T-Rex Mech Wars",
                    "desc": "T-Rex Mech Wars brings the might of the T-Rex to mech battles. Control a T-Rex inspired mech and fight for supremacy in this prehistoric-themed combat game."
                },
                "Battle Robot Samurai Age": {
                    "name": "Samurai Mech Duel",
                    "desc": "Samurai Mech Duel merges samurai combat with mech technology. Wield a sword in your mech and engage in duels that reflect the samurai spirit."
                },
                "Archery Training": {
                    "name": "Arrow Mastery",
                    "desc": "Arrow Mastery is an archery training simulation that helps you refine your archery skills. Improve your aim, strength, and technique through various training exercises."
                },
                "13 Nights": {
                    "name": "Twilight Survival",
                    "desc": "Twilight Survival is a horror-adventure game where you endure thirteen spine-chilling nights. Face supernatural entities, solve puzzles, and unveil the haunting truth."
                }
            },
    };
  },
  watch: {
    $route(to) {
      this.gameid = to.params.gameid;
      this.getGameDetail();
      this.$refs.gameList.refresh();
    },
  },
  mounted() {
    console.log('GameDetailView mounted');
    this.gameid = this.$route.params.gameid;
    this.getGameDetail();
  },
  methods: {
    getName(name) {
      if(this.names[name]) {
        return this.names[name].name
      } else {
        return name
      }
    },
    getDesc(name) {
      if(this.names[name]) {
        return this.names[name].desc
      } else {
        return ""
      }
    },
    getGameDetail() {
      gameApi.getGameInfo(this.gameid).then((response) => {
        console.log('response', response);
        if (response.status === 200 && response.data.code == 0) {
          this.gameData = response.data.data;
          this.gameData.desc = this.getDesc(response.data.data.name);
          this.gameData.name = this.getName(response.data.data.name);
          this.dataLoaded = true;
          console.log('gameData: ', this.gameData);
        } else {
          console.log('request gameApi.getGameDetail error', response);
        }
      });
    },
    playGame() {
      console.log('playGame');
      this.$router.push({ name: 'play', params: { gameid: this.gameid, gamename: this.getGameNameWithDash(this.gameData.name) } });
    },
    getGameName(str) {
      if (!str) {
        return
      }
      return str.replace(/([a-z0-9])([A-Z0-9])/g, '$1 $2')
    },
    getGameNameWithDash(str) {
      // convert string with white space into array
      if (!str) {
        return
      }
      return str.replace(/([a-z0-9])([A-Z0-9])/g, '$1-$2').split(' ').join('-').toLowerCase();
    },
    kFormatter(num) {
      return Math.abs(num) > 999 ? Math.sign(num) * ((Math.abs(num) / 1000).toFixed(1)) + 'k' : Math.sign(num) * Math.abs(num)
    }
  },
};
</script>

<style lang="scss" scoped>
@import "../app.scss";

.btn-primary {
  color: #ffffff;
  font-size: x-large;
  font-weight: 500;
  background-color: $primary-color;
  // border-color: #c80000;
}

.btn-animation {
  transform-origin: center;
  animation: 0.75s infinite ease-in-out alternate b-icon-animation-throb;
}

.text-collapse-wrapper {
  display: flex;
  // margin: 50px auto;
  max-width: 900px;
  overflow: hidden;
  /*   resize: horizontal; */
  // border-radius: 8px;
  // padding: 15px;
  // box-shadow: 20px 20px 60px #bebebe,
  //   -20px -20px 60px #ffffff;
}

.text-collapse-wrapper .text {
  font-size: 14px;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: justify;
  /* display: flex; */
  /*   display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical; */
  position: relative;
  line-height: 1.3;
  max-height: 2.5em;
  transition: .3s max-height;
}

.text-collapse-wrapper .text::before {
  content: '';
  height: calc(100% - 16px);
  float: right;
}

.text-collapse-wrapper .text::after {
  content: '';
  width: 999vw;
  height: 999vw;
  position: absolute;
  box-shadow: inset calc(100px - 999vw) calc(30px - 999vw) 0 0 #fff;
  margin-left: -100px;
}

.text-collapse-wrapper .btn {
  position: relative;
  float: right;
  clear: both;
  margin-left: 12px;
  font-size: 12px;
  padding: 0 8px;
  background: #0065BD;
  line-height: 12px;
  border-radius: 4px;
  color: #fff;
  cursor: pointer;
  /* margin-top: -30px; */
}

.text-collapse-wrapper .btn::after {
  content: 'expand'
}

.text-collapse-wrapper .exp {
  display: none;
}

.text-collapse-wrapper .exp:checked+.text {
  max-height: none;
}

.text-collapse-wrapper .exp:checked+.text::after {
  visibility: hidden;
}

.text-collapse-wrapper .exp:checked+.text .btn::before {
  visibility: hidden;
}

.text-collapse-wrapper .exp:checked+.text .btn::after {
  content: 'hide'
}

.text-collapse-wrapper .btn::before {
  content: '...';
  position: absolute;
  left: -5px;
  color: #333;
  transform: translateX(-100%)
}
</style>