import Vue from 'vue'
import App from './App.vue'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import './app.scss'
import router from './router'
import InfiniteLoading from 'vue-infinite-loading';

Vue.config.productionTip = false

// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue)
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin)

Vue.use(InfiniteLoading, { /* 配置 */ });

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
